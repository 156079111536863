<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>班级荣誉</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-button type="primary" @click="dialogVisible = true" v-if="usergroup != 3" plain>新增荣誉</el-button>
      <el-button type="success" @click="exportToExcel" :disabled="disabled" plain>导出数据</el-button>
      <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
        <el-table-column prop="id" label="序号" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="title" label="获奖名称" min-width="200px"></el-table-column>
        <el-table-column prop="rank" label="获奖名次" header-align="center" align="center"></el-table-column>
        <el-table-column prop="leixing" label="获奖类型" header-align="center" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.leixing == 1">班级</span>
            <span v-else-if="scope.row.leixing == 2">院级</span>
            <span v-else-if="scope.row.leixing == 3">校级</span>
            <span v-else-if="scope.row.leixing == 4">镇级</span>
            <span v-else-if="scope.row.leixing == 5">县级</span>
            <span v-else-if="scope.row.leixing == 6">市级</span>
            <span v-else-if="scope.row.leixing == 7">省级</span>
            <span v-else-if="scope.row.leixing == 8">国家级</span>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="获奖时间" min-width="120px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="people_num" label="参与人数" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column label="操作" width="240px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="handleShow(scope.row)">查看详情</el-button>
            <el-button size="mini" type="info" @click="handleEdit(scope.row)" v-if="usergroup != 3">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDel(scope.row)" v-if="usergroup != 3">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :pager-count="7"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length > 0 ? tableData.length : null"
        background
        style="float: right; margin-top: 20px"
      >
      </el-pagination>
      <el-dialog
        title="新增荣誉"
        :visible.sync="dialogVisible"
        :width="create_width"
        :before-close="handleClose">
          <el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px">
            <el-form-item label="获奖名称" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="获奖名次" prop="rank">
              <el-input v-model="form.rank"></el-input>
            </el-form-item>
            <el-form-item label="获奖类型" prop="leixing">
              <el-select v-model="form.leixing" placeholder="获奖类型">
                <el-option label="班级" value="1"></el-option>
                <el-option label="院级" value="2"></el-option>
                <el-option label="校级" value="3"></el-option>
                <el-option label="镇级" value="4"></el-option>
                <el-option label="县级" value="5"></el-option>
                <el-option label="市级" value="6"></el-option>
                <el-option label="省级" value="7"></el-option>
                <el-option label="国家级" value="8"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="获奖时间" prop="time">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" v-model="form.time" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="参与人员" prop="peoples">
              <label>
                <input type="checkbox" v-model="checkAll" @change="handleCheckAll">
                <span :class="{'is-checked': checkAll}"><span>全选</span></span>
              </label><br>
              <label v-for="item in people_list" :key="item.id">
                <input type="checkbox" v-model="form.peoples" :value="item.id" @change="handleCheckedColumn">
                <span :class="{'is-checked': form.peoples.indexOf(item.id) > -1}"><span>{{item.name}}</span></span>
              </label>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('form')">确认增加</el-button>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="编辑"
        :visible.sync="show_edit_dialog"
        :width="create_width"
        :before-close="handleClose_edit">
          <el-form ref="edit_form" status-icon :model="edit_digital_array" :rules="rules" label-width="80px">
            <el-form-item label="获奖名称" prop="title">
              <el-input v-model="edit_digital_array.title"></el-input>
            </el-form-item>
            <el-form-item label="获奖名次" prop="rank">
              <el-input v-model="edit_digital_array.rank"></el-input>
            </el-form-item>
            <el-form-item label="获奖类型" prop="leixing">
              <el-select v-model="edit_digital_array.leixing" placeholder="获奖类型">
                <el-option label="班级" value="1"></el-option>
                <el-option label="院级" value="2"></el-option>
                <el-option label="校级" value="3"></el-option>
                <el-option label="镇级" value="4"></el-option>
                <el-option label="县级" value="5"></el-option>
                <el-option label="市级" value="6"></el-option>
                <el-option label="省级" value="7"></el-option>
                <el-option label="国家级" value="8"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="获奖时间" prop="time">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" v-model="edit_digital_array.time" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="参与人员" prop="peoples">
              <label v-for="item in people_list" :key="item.id">
                <input type="checkbox" v-model="edit_digital_array.peoples" :value="item.id">
                <span :class="{'is-checked': edit_digital_array.peoples.indexOf(item.id) > -1}"><span>{{item.name}}</span></span>
              </label>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitToEdit('edit_form')">确认修改</el-button>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose_edit">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog title="荣誉详情" :visible.sync="show_digital_dialog" :width="create_width">
        <div v-loading="show_loading" element-loading-text="拼命加载中">
          <span>序号：{{show_digital_array.title}}</span><br>
          <span>获奖名称：{{show_digital_array.title}}</span><br>
          <span>获奖名次：{{show_digital_array.rank}}</span><br>
          <span>获奖类型：
            <span v-if="show_digital_array.leixing == 1">班级</span>
            <span v-else-if="show_digital_array.leixing == 2">院级</span>
            <span v-else-if="show_digital_array.leixing == 3">校级</span>
            <span v-else-if="show_digital_array.leixing == 4">镇级</span>
            <span v-else-if="show_digital_array.leixing == 5">县级</span>
            <span v-else-if="show_digital_array.leixing == 6">市级</span>
            <span v-else-if="show_digital_array.leixing == 7">省级</span>
            <span v-else-if="show_digital_array.leixing == 8">国家级</span>  
          </span><br>
          <span>获奖时间：{{show_digital_array.time}}</span><br>
          <span>参与人数：{{show_digital_array.people_num}}</span><br>
          <span>参与人员：{{show_digital_array.people_names}}</span><br>
          <span>记录人：{{show_digital_array.poster}}</span><br>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="show_digital_dialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
    }

    input[type="checkbox"] + span {
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        padding: 6px 10px;
        font-size: 14px;
        border: 1px solid rgb(215, 215, 215);
        border-radius: 5px;
        font-weight: 400;
        background-color: white;
    }

    input[type="checkbox"]:checked + span {
        border-color: #67C23A;
        background-color: #67C23A;
        color: white;
    }
    
</style>

<script>
  import axios from 'axios'
  import * as XLSX from 'xlsx'
  import { Loading } from 'element-ui'

  export default {
      name:'Class',
      data() {
          return {
            checkAll: false,
            isALL: false,
            isALL_edit: false,
            dialogVisible: false,
            show_digital_dialog: false,
            show_edit_dialog: false,
            show_digital_array: {},
            edit_digital_array: {
              peoples: []
            },
            loading: true,
            show_loading: false,
            people_list: [],
            tableData: [],

            create_width: '50%',

            usergroup: 3,

            disabled: false,

            pickerOptions: {
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
            },

            form: {
              title: '',
              rank: '',
              leixing: '',
              time: '',
              peoples:[]
            },
            rules: {
              title: [
                { required: true, message: '请输入获奖名称', trigger: 'blur' }
              ],
              rank: [
                { required: true, message: '请输入获奖名次', trigger: 'blur' }
              ],
              
              leixing: [
                { required: true, message: '请选择活动类型', trigger: 'change' }
              ],
              time: [
                { required: true, message: '请选择获奖日期', trigger: 'change' }
              ],
              peoples: [
                { type: 'array', required: true, message: '请至少选择一名参与人员', trigger: 'change' }
              ]
            },
          
            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.$refs['form'].resetFields();
              this.show_digital_array = {};
              this.dialogVisible = false;
              done();
            })
            .catch(_ => {});
        },
        handleClose_edit(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.show_edit_dialog = false;
              this.edit_digital_array= {
                peoples: []
              },
              done();
            })
            .catch(_ => {});
        },
        handleCheckAll(event) {
          if(event.target.checked) {
            this.form.peoples = this.people_list.map(item => item.id);
          } else {
            this.form.peoples = []
          }
        },
        handleCheckedColumn(value) {
          if(value.length === this.people_list.length) {
            this.checkAll = true;
          } else {
            this.checkAll = false;
          }
        },
        handleShow(row) {
          this.show_digital_dialog = true
          this.show_loading = true

          let tail=0;
          let people_names=[];

          for(var i=0;i<this.people_list.length;i++) {
            if(this.people_list[i].id == this.tableData[row.id - 1].peoples[tail]) {
              people_names.push(this.people_list[i].name);
              tail++;
            }
          }
          let people_names_string = people_names.join("、");

          this.show_digital_array = row
          this.show_digital_array.people_names = people_names_string

          this.show_loading = false

          
        },
        handleEdit(row){
          this.show_edit_dialog = true
          this.edit_digital_array = JSON.parse(JSON.stringify(row))
        },
        onSubmit(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.form.peoples.sort((a, b) => parseInt(a) - parseInt(b));
              let peopleArraytoString=this.form.peoples.join(",");
              let data = new FormData();
              data.append('title', this.form.title)
              data.append('time', this.form.time)
              data.append('rank', this.form.rank)
              data.append('leixing', this.form.leixing)
              data.append('people', peopleArraytoString)

              this.axios({
                method:"post",
                url: '/api/getClassList.php?method=setNew',
                data: data,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
                  
                  if(res.data.code == 200) {
                    let new_data = {
                      id: this.tableData.length + 1,
                      unid: res.data.new_data.unid,
                      title: res.data.new_data.title,
                      time: res.data.new_data.time,
                      rank: res.data.new_data.rank,
                      poster: res.data.new_data.poster,
                      leixing: res.data.new_data.leixing,
                      classid: res.data.new_data.classid,
                      peoples: res.data.new_data.peoples,
                      people_num: res.data.new_data.people_num
                    }
                    this.tableData.push(new_data);
                    this.$message.success('添加成功！');
                    this.$refs[formName].resetFields();
                    this.dialogVisible = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        onSubmitToEdit(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.edit_digital_array.peoples.sort((a, b) => parseInt(a) - parseInt(b));

              let peopleArraytoString=this.edit_digital_array.peoples.join(",");
              let data = new FormData();

              data.append('unid', this.edit_digital_array.unid)
              data.append('title', this.edit_digital_array.title)
              data.append('time', this.edit_digital_array.time)
              data.append('rank', this.edit_digital_array.rank)
              data.append('leixing', this.edit_digital_array.leixing)
              data.append('people', peopleArraytoString)

              this.axios({
                method:"post",
                url: '/api/getClassList.php?method=edit',
                data: data,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                  if(res.data.code == 200) {
                    for(var i=0;i<this.tableData.length;i++) {
                      if(this.tableData[i].id == this.edit_digital_array.id) {
                        this.tableData[i] = JSON.parse(JSON.stringify(this.edit_digital_array))
                        this.tableData[i].people_num = this.edit_digital_array.peoples.length
                        break;
                      }
                    }
                    this.$message.success('修改成功！');
                    this.show_edit_dialog = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        handleDel(formdata) {
          this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.get('/api/getClassList.php',{params:{method:'del', id:formdata.unid}})
              .then(res => {
                if(res.data.code != null && res.data.code == 400) {
                  this.$message.error('登录状态过期，请重新登录');
                  localStorage.clear('name');
                  localStorage.clear('isLogin');
                  this.$router.push('/login?refer=' + window.location.pathname)
                }
                else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                if(res.data.code == 200) {
                  this.$message({
                    type: 'success',
                    message: '删除记录：“'+formdata.title+'”成功!'
                  });
                  for(var i=0;i<this.tableData.length;i++) {
                    if(this.tableData[i].id == formdata.id) {
                      this.tableData.splice(i, 1);
                      for(var j=i;j<this.tableData.length;j++) {
                        this.tableData[j].id--;
                      }
                      break;
                    }
                  }
                } else {
                  this.$message.error('失败：' + res.data.msg)
                }
              }).catch(err => {
                console.log(err)
              })
          })
        },
        getClassList() {
          axios.get('/api/getClassList.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              
              this.people_list = res.data.people_list
              this.tableData = res.data.result
            
              this.pageCount = res.data.result.length

              this.usergroup = res.data.usergroup
              this.loading = false

              console.log(res)

            }).catch(err => {
              console.log(err)
            })
        },
        exportToExcel() {
          this.disabled = true
          let loading = Loading.service({
            text: '数据生成中，请稍后...',
            background: 'rgba(0,0,0,.5)'
          })

          let arr = this.tableData.map(item => {
            let leixing;

            if(item.leixing == 1) leixing = '班级';
            if(item.leixing == 2) leixing = '院级';
            if(item.leixing == 3) leixing = '校级';
            if(item.leixing == 4) leixing = '镇级';
            if(item.leixing == 5) leixing = '县级';
            if(item.leixing == 6) leixing = '市级';
            if(item.leixing == 7) leixing = '省级';
            if(item.leixing == 8) leixing = '国家级';

            let people_names=[];
            let tail=0;

            for(var i=0;i<this.people_list.length;i++) {
              if(this.people_list[i].id == item.peoples[tail]) {
                people_names.push(this.people_list[i].name);
                tail++;
              }
            }
            let people_names_string = people_names.join("、");

            return {
              序号: item.id,
              获奖名称: item.title,
              获奖名次: item.rank,
              获奖类型: leixing,
              获奖时间: item.time,
              参与人员: people_names_string,
              记录人: item.poster
            }
          })
            

          //const data = this.tableData // 表格数据
          const ws = XLSX.utils.json_to_sheet(arr)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, '班级荣誉')
          XLSX.writeFile(wb, `班级荣誉${new Date().getTime()}.xlsx`)

          loading.close()
          this.disabled = false
        },
        handleResize() {
          if (window.innerWidth < 992) {
            this.create_width = "100%"
          } else {
            this.create_width = "50%"
          }
        }
      },
      mounted() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize);
      },
      created() {
        this.loading = false
        this.getClassList()
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
      }
  };
</script>
