<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>消息中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-table v-loading="loading" border :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
        <el-table-column prop="id" label="序号" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="title" :show-overflow-tooltip="true" label="标题" min-width="200px"></el-table-column>
        <el-table-column prop="poster" label="发布者" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="time" label="发布时间" min-width="160px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="isRead" label="是否阅读" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isRead == true ? 'success' : 'warning'" size="medium">{{scope.row.isRead == true ? '已读' : '未读'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="control" label="操作" min-width="100px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="handleShow(scope.row.id)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :pager-count="7"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length > 0 ? tableData.length : null"
        background
        style="float: right; margin-top: 20px"
      >
      </el-pagination>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>

<script>
  import axios from 'axios'
  export default {
      name:'Message',
      data() {
          return {
            tableData: [],
            loading: true,
          
            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,    //数据的总条数，如果数据是后端接口返回的，则此值需修改

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        handleShow(id) {
          this.$router.push('/user/message/'+id)
        },
        getMessage() {
          axios.get('/api/getMessage.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              
              this.tableData = res.data
              this.pageCount = res.data.length
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        },
      },
      created() {
        this.loading = true
        this.getMessage()
      }
  };
</script>
