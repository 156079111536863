<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>班费明细</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-button type="primary" @click="dialogVisible = true" v-if="usergroup != 3" plain>手动添加记录</el-button>
      <el-button type="danger" @click="exportToExcel" :disabled="disabled" v-if="usergroup != 3" plain>导出数据</el-button>
      <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
        <el-table-column prop="id" label="流水号" min-width="80px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="title" :show-overflow-tooltip="true" label="标题" min-width="200px"></el-table-column>
        <el-table-column prop="money" label="金额" min-width="80px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="after_money" label="变动后金额" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="method" label="支出/收入" min-width="100px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.method == 1 ? 'danger' : 'success'" size="medium">{{scope.row.method == "1" ? "支出" : "收入"}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="poster" label="申请人" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="time" label="时间" min-width="160px" header-align="center" align="center"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :pager-count="7"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length > 0 ? tableData.length : null"
        background
        style="float: right; margin-top: 20px"
      >
      </el-pagination>
      
      <el-dialog
        title="手动添加记录"
        :visible.sync="dialogVisible"
        :width="create_width"
        :before-close="handleClose">
          <el-form ref="form" status-icon :model="form" :rules="rules" label-width="120px">
            <el-form-item label="标题" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="支出/收入金额" prop="money">
              <el-input v-model="form.money"></el-input>
            </el-form-item>
            <el-form-item label="支出/收入" prop="method">
              <el-select v-model="form.method" placeholder="请选择支出/收入">
                <el-option label="支出" value="1"></el-option>
                <el-option label="收入" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="验证码" prop="yz_code">
              <el-input v-model="form.yz_code" placeholder="请输入验证码（不区分大小写）">
                <template slot="append">
                  <div @click="refreshCode">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('form')">提 交</el-button>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>

<script>
  import axios from 'axios'
  import * as XLSX from 'xlsx'
  import { Loading } from 'element-ui'

  import SIdentify from '@/components/SIdentify'
  export default {
      components: {SIdentify},
      name:'Fee',
      data() {
          var check_yz_code = (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请输入验证码'));
            } else if (value !== this.identifyCode) {
              callback(new Error('验证码错误!'));
            } else {
              callback();
            }
          };
          return {
            dialogVisible: false,
            identifyCode: '',
            identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
            loading: true,
            tableData: [],
            disabled: false,
            usergroup: 3,

            create_width: '50%',
          
            form:{
              title:'',
              money:'',
              method:'',
              yz_code:''
            },

            rules: {
              title: [
                { required: true, message: '请输入标题', trigger: 'blur' }
              ],
              money: [
                { required: true, message: '请输入支出/收入的金额', trigger: 'blur' }
              ],
              yz_code:[
                { required: true, validator: check_yz_code, trigger: 'blur'}
              ],
              method:[
                { required: true, message: '请选择支出/收入', trigger: 'change' }
              ]
            },
          
            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.$refs['form'].resetFields();
              this.checkAll = false;
              this.isALL = false;
              this.dialogVisible = false;
              done();
            })
            .catch(_ => {});
        },
        onSubmit(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              let data = new FormData();
              data.append('title', this.form.title);
              data.append('fee', this.form.money);
              data.append('status', this.form.method);


              this.axios({
                method:"post",
                url: '/api/getFeeList.php?method=setNew',
                data: data,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null) this.$message.error(res.data.msg);
                  
                  if(res.data.code == 200 && res.data.code != 200) {
                    this.tableData.unshift(res.data.new_data);
                    this.$message.success('添加成功！');
                    this.$refs[formName].resetFields();
                    this.dialogVisible = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        refreshCode () {
            this.identifyCode = ''
            this.makeCode(this.identifyCodes,4);
        },
        makeCode (o,l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
            }
        },
        randomNum (min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        getFeeList() {
          axios.get('/api/getFeeList.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              this.tableData = res.data.results
              this.pageCount = res.data.results.length
              this.usergroup = res.data.usergroup
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        },
        exportToExcel() {
          this.disabled = true
          let loading = Loading.service({
            text: '数据生成中，请稍后...',
            background: 'rgba(0,0,0,.5)'
          })

          let arr = this.tableData.map(item => {
            let leixing;
            if(item.method == 1) leixing = '支出';
            if(item.method == 2) leixing = '收入';
            
            return {
              流水号: item.id,
              标题: item.title,
              "金额（单位：元）": item.money,
              "变动后金额（单位：元）": item.after_money,
              支出收入: leixing,
              申请人: item.poster,
              时间: item.time
            }
          })
            

          //const data = this.tableData // 表格数据
          const ws = XLSX.utils.json_to_sheet(arr)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, '班费明细')
          XLSX.writeFile(wb, `班费明细${new Date().getTime()}.xlsx`)

          loading.close()
          this.disabled = false
        },
        handleResize() {
          if (window.innerWidth < 992) {
            this.create_width = "100%"
          } else {
            this.create_width = "50%"
          }
        }
      },
      mounted(){
        this.identifyCode = ''
        this.makeCode(this.identifyCodes,4)

        this.handleResize()
        window.addEventListener("resize", this.handleResize);
      },
      created() {
        this.loading = true
        this.getFeeList()
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
      }
  };
</script>
