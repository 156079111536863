<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>报销申请</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-button type="primary" @click="dialogVisible = true" plain>申请报销</el-button>
      <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
        <el-table-column prop="id" label="流水号" min-width="80px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="title" :show-overflow-tooltip="true" label="标题" min-width="200px"></el-table-column>
        <el-table-column prop="money" label="金额" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="method" label="支出/收入" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.method == 1 ? 'danger' : 'success'" size="medium">{{scope.row.method == "1" ? "支出" : "收入"}}</el-tag>
          </template>
        </el-table-column>
         <el-table-column prop="status" label="申请状态" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'primary' : (scope.row.status == 1 ? 'success' : 'danger')" size="medium">{{scope.row.status == "0" ? "待审核" : (scope.row.status == 1 ? "通过" : "驳回")}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间" min-width="150px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="control" label="操作" min-width="100px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="handleShow(scope.row,scope.row.id)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :pager-count="7"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length > 0 ? tableData.length : null"
        background
        style="float: right; margin-top: 20px"
      >
      </el-pagination>
      
      <el-dialog
        title="新增申请"
        :visible.sync="dialogVisible"
        :width="create_width"
        :before-close="handleClose">
          <el-form ref="form" status-icon :model="form" :rules="rules" label-width="120px">
            <el-form-item label="报销名称" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="用途" prop="yt">
              <el-input v-model="form.yt"></el-input>
            </el-form-item>
            <el-form-item label="报销金额" prop="money">
              <el-input v-model="form.money" placeholder="单位：元" oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
            </el-form-item>
            <el-form-item label="消费时间" prop="time">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" v-model="form.time" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item label="报销凭证" prop="img1">
              <el-upload
                class="upload-demo"
                ref="upload_1" 
                action="" 
                :before-upload="beforeUpload_1" 
                :on-change="handleChange_1"
                :on-remove="handleRemove_1"
                :on-exceed="handleExceed_1"
                :file-list="fileList_1"
                accept=".png, .jpeg, .jpg" 
                :multiple="false" 
                :limit="1"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
              </el-upload>
            </el-form-item>
            <el-form-item label="收款二维码" prop="img2">
              <el-upload
                class="upload-demo"
                ref="upload_2" 
                action="" 
                :before-upload="beforeUpload_2" 
                :on-change="handleChange_2"
                :on-remove="handleRemove_2"
                :on-exceed="handleExceed_2"
                :file-list="fileList_2"
                accept=".png, .jpeg, .jpg" 
                :multiple="false" 
                :limit="1"
                :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2MB</div>
              </el-upload>
            </el-form-item>
            
            <el-form-item label="验证码" prop="yz_code">
              <el-input v-model="form.yz_code" placeholder="请输入验证码（不区分大小写）">
                <template slot="append">
                  <div @click="refreshCode">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('form')">确认申请</el-button>
            </el-form-item>
          </el-form>
        <div style="float:left;"><i class="el-icon-info"></i>注意：请<span style="color: red;"><b>不要</b></span>多次提交重复的报销申请，否则后果自负！</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>
      <el-dialog title="详情" :visible.sync="show_digital_dialog" :width="create_width">
        <div v-loading="show_loading" element-loading-text="拼命加载中">
          报销名称：<span>{{show_digital_array.title}}</span><br>
          用途：<span style="color:red;">{{show_digital_array.yt}}</span><br>
          报销金额：<span>{{show_digital_array.money}}</span>元<br>
          消费时间：<span>{{show_digital_array.xiaofei_time}}</span><br>
          申请状态：<span><el-tag :type="show_digital_array.status == 0 ? 'primary' : show_digital_array.status == 1 ? 'success' : 'danger'" size="mini">{{show_digital_array.status == 0 ? '待审核' : show_digital_array.status == 1 ? "通过" : "驳回"}}</el-tag></span><br>
          申请批复：<span>{{show_digital_array.ps}}</span><br>
          报销凭证：<span><div style="text-align:center;margin 0 auto;width:300px;justify-content: center;align-items: center;"><img style="width:100%;" :src="show_digital_array.img1"></div></span><br>
          收款二维码：<span><div style="text-align:center;margin 0 auto;width:300px;justify-content: center;align-items: center;"><img style="width:100%;" :src="show_digital_array.img2"></div></span><br>
          申请人：<span>{{show_digital_array.poster}}</span><br>
          申请时间：<span>{{show_digital_array.time}}</span><br>
          处理人：<span>{{show_digital_array.dealer}}</span><br>
          处理时间：<span>{{show_digital_array.deal_time}}</span><br>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="show_digital_dialog = false">关闭</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>
<style>
  .el-upload input{
    display: none !important;
  }
</style>

<script>
  import axios from 'axios'
  import SIdentify from '@/components/SIdentify'
  export default {
      components: {SIdentify},
      name:'Baoxiao',
      data() {
          var check_yz_code = (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请输入验证码'));
            } else if (value !== this.identifyCode) {
              callback(new Error('验证码错误!'));
            } else {
              callback();
            }
          };
          return {
            show_digital_array: {},
            show_digital_dialog: false,
            dialogVisible: false,
            loading: true,
            show_loading: false,
            identifyCode: '',
            identifyCodes: '1234567890abcdefjhijkinopqrsduvwxyz',
            tableData: [],
            fileList_1:[],
            fileList_2:[],

            create_width: '50%',
          
            form:{
              title:'',
              money:'',
              time:'',
              yt:'',
              yz_code:''
            },

            pickerOptions: {
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
            },

            rules: {
              title: [
                { required: true, message: '请输入标题', trigger: 'blur' }
              ],
              money: [
                { required: true, message: '请输入支出/收入的金额', trigger: 'blur' }
              ],
              time: [
                { required: true, message: '选择消费时间', trigger: 'change' }
              ],
              yt: [
                { required: true, message: '请输入消费用途', trigger: 'blur' }
              ],
              yz_code:[
                { required: true, validator: check_yz_code, trigger: 'blur'}
              ]
            },
          
            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        beforeUpload_1(file) {
          let size = file.size / 1024 / 1024;
          if (size > 2) {
            this.$message.warning("文件大小不得超过2M");
          }
        },
        handleChange_1(file, fileList_1) {
          if (file) {
            this.fileList_1 = fileList_1.slice(-3);
          }
        },
        handleRemove_1(file, fileList_1) {
          this.fileList_1 = [];
        },
        handleExceed_1(files, fileList_1) {
          this.$message.warning(
            '只能选择1个文件，如果要重新选择文件，请先在上传列表中删除原来的文件'
          );
        },
        beforeUpload_2(file) {
          let size = file.size / 1024 / 1024;
          if (size > 2) {
            this.$message.warning("文件大小不得超过2M");
          }
        },
        handleChange_2(file, fileList_2) {
          if (file) {
            this.fileList_2 = fileList_2.slice(-3);
          }
        },
        handleRemove_2(file, fileList_2) {
          this.fileList_2 = [];
        },
        handleExceed_2(files, fileList_2) {
          this.$message.warning(
            '只能选择1个文件，如果要重新选择文件，请先在上传列表中删除原来的文件'
          );
        },
        handleShow(row,id) {
          this.show_loading = true
          this.show_digital_dialog = true
          
          axios.get('/api/getBaoxiaoList.php',{params:{mode:'detail',id:id}})
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
              else{
                this.show_digital_array = row
                this.show_digital_array.img1 = 'https://class.ljcljc.cn/public/file/' + res.data.img1
                this.show_digital_array.img2 = 'https://class.ljcljc.cn/public/file/' + res.data.img2
                this.show_digital_array.yt = res.data.yt
                this.show_digital_array.ps = res.data.ps
                this.show_digital_array.dealer = res.data.dealer
                this.show_digital_array.deal_time = res.data.deal_time
                this.show_digital_array.xiaofei_time = res.data.xiaofei_time
                this.show_loading = false
              }
            }).catch(err => {
              console.log(err)
            })


          
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.$refs['form'].resetFields();
              this.checkAll = false;
              this.isALL = false;
              this.dialogVisible = false;
              done();
            })
            .catch(_ => {});
        },
        onSubmit(formName) {
          if (this.fileList_1.length === 0) {
            this.$message.warning("请选择报销凭证");
          }
          if (this.fileList_2.length === 0) {
            this.$message.warning("请选择收款二维码");
          }
          this.$refs[formName].validate((valid) => {
            if (valid) {
              let data = new FormData();

              data.append('title', this.form.title);
              data.append('money', this.form.money);
              data.append('yt', this.form.yt);
              data.append('file1',this.fileList_1[0].raw);
              data.append('file2',this.fileList_2[0].raw);
              data.append('xiaofei_time',this.form.time);

              this.axios({
                method:"post",
                url: '/api/getBaoxiaoList.php?mode=setNew',
                data: data,
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                  if(res.data.code == 200) {
                    this.tableData.unshift(res.data.new_data);
                    this.$message.success('添加成功！');
                    this.$refs[formName].resetFields();
                    this.dialogVisible = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        refreshCode () {
            this.identifyCode = ''
            this.makeCode(this.identifyCodes,4);
        },
        makeCode (o,l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
            }
        },
        randomNum (min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        submitUpload() {
          this.$refs.upload.submit();
        },
        handleRemove(file, fileList) {
          console.log(file, fileList);
        },
        handlePreview(file) {
          console.log(file);
        },
        getBaoxiaoList() {
          axios.get('/api/getBaoxiaoList.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              this.tableData = res.data
              this.pageCount = res.data.length
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        },
        handleResize() {
          if (window.innerWidth < 992) {
            this.create_width = "100%"
          } else {
            this.create_width = "50%"
          }
        }
      },
      mounted(){
        this.identifyCode = ''
        this.makeCode(this.identifyCodes,4)

        this.handleResize()
        window.addEventListener("resize", this.handleResize);
      },
      created() {
        this.loading = true
        this.getBaoxiaoList()
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
      }
  };
</script>
