import { render, staticRenderFns } from "./VoteData.vue?vue&type=template&id=14ce1002&scoped=true&"
import script from "./VoteData.vue?vue&type=script&lang=js&"
export * from "./VoteData.vue?vue&type=script&lang=js&"
import style0 from "./VoteData.vue?vue&type=style&index=0&id=14ce1002&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14ce1002",
  null
  
)

export default component.exports