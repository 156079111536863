<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>登记表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-button type="primary" @click="dialogVisible = true" plain>创建登记表</el-button>
      <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
        <el-table-column prop="id" label="登记表ID" min-width="80px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="title" :show-overflow-tooltip="true" label="登记表标题" min-width="200px"></el-table-column>
        <el-table-column prop="people_num" label="应完成人数" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="people_num_finish" label="已完成人数" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="poster" label="创建人" min-width="80px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="time" label="创建时间" min-width="160px" header-align="center" align="center"></el-table-column>
        <el-table-column label="操作" width="340px" header-align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="toDataPanel(scope.row.id)">查看数据</el-button>
            <el-button size="mini" type="info" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button v-if="scope.row.ifallow==true" size="mini" type="success" @click="handleShare(scope.row)">分享链接</el-button>
            <el-button size="mini" type="danger" @click="handleDel(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :pager-count="7"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length > 0 ? tableData.length : null"
        background
        style="float: right; margin-top: 20px"
      >
      </el-pagination>
      <el-dialog
        title="创建登记表"
        :visible.sync="dialogVisible"
        :width="create_width"
        :before-close="handleClose">
          <el-form ref="form" status-icon :model="form" :rules="rules" label-width="130px">
            <el-form-item label="登记表标题" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="允许用户自行填报" prop="ifallow">
              <el-switch v-model="form.ifallow"></el-switch>
            </el-form-item>
            <el-form-item label="固定时间模式" v-if="form.ifallow === true" prop="iflimit">
              <el-switch v-model="form.iflimit"></el-switch>
            </el-form-item>
            <el-form-item label="开放固定时间段" prop="limit_time" v-if="form.iflimit === true">
              <el-date-picker v-model="form.limit_time" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="截止时间" prop="jiezhi_time" v-if="form.iflimit === false && form.ifallow === true">
              <el-date-picker v-model="form.jiezhi_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="允许截止时间过后提交" prop="ifouttime" v-if="form.iflimit === false && form.ifallow === true">
              <el-switch v-model="form.ifouttime"></el-switch>
            </el-form-item>
            <el-form-item label="应完成人员" prop="peoples">
              <label>
                <input type="checkbox" v-model="checkAll" @change="handleCheckAll">
                <span :class="{'is-checked': checkAll}"><span>全选</span></span>
              </label><br>
              <label v-for="item in people_list" :key="item.id">
                <input type="checkbox" v-model="form.peoples" :value="item.id" @change="handleCheckedColumn">
                <span :class="{'is-checked': form.peoples.indexOf(item.id) > -1}"><span>{{item.name}}</span></span>
              </label>
            </el-form-item>
            <el-form-item label="验证码" prop="yz_code">
              <el-input v-model="form.yz_code" placeholder="请输入验证码（不区分大小写）">
                <template slot="append">
                  <div @click="refreshCode">
                    <s-identify :identifyCode="identifyCode"></s-identify>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('form')">创建</el-button>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="编辑"
        :visible.sync="show_edit_dialog"
        :width="create_width"
        :before-close="handleClose_edit">
          <el-form ref="edit_form" status-icon :model="edit_digital_array" :rules="rules_edit" label-width="130px">
            <el-form-item label="登记表标题" prop="title">
              <el-input v-model="edit_digital_array.title"></el-input>
            </el-form-item>
            <el-form-item label="允许用户自行填报" prop="ifallow">
              <el-switch v-model="edit_digital_array.ifallow"></el-switch>
            </el-form-item>
            <el-form-item label="固定时间模式" v-if="edit_digital_array.ifallow === true" prop="iflimit">
              <el-switch v-model="edit_digital_array.iflimit"></el-switch>
            </el-form-item>
            <el-form-item label="开放固定时间段" prop="limit_time" v-if="edit_digital_array.iflimit === true">
              <el-date-picker v-model="edit_digital_array.limit_time" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="截止时间" prop="jiezhi_time" v-if="edit_digital_array.iflimit === false && edit_digital_array.ifallow === true">
              <el-date-picker v-model="edit_digital_array.jiezhi_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="允许截止时间过后提交" prop="ifouttime" v-if="edit_digital_array.iflimit === false && edit_digital_array.ifallow === true">
              <el-switch v-model="edit_digital_array.ifouttime"></el-switch>
            </el-form-item>
            <el-form-item label="应完成人员" prop="peoples">此项不可修改<br>
              <label v-for="item in people_list" :key="item.id">
                <input type="checkbox" v-model="edit_digital_array.peoples" :value="item.id" :disabled="disabled">
                <span :class="{'is-checked': edit_digital_array.peoples.indexOf(item.id) > -1, 'disabled':disabled}"><span>{{item.name}}</span></span>
              </label>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitToEdit('edit_form')">确认修改</el-button>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose_edit">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog title="分享链接" :visible.sync="share_link_dialog" :width="create_width">
        <span style="font-size:17px;">登记表ID：{{show_digital_array.id}}</span><br>
        <span style="font-size:17px;">链接：{{now_request_url}}/user/finish_register/{{show_digital_array.id}}</span><br>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="share_link_dialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    .el-input-group__append{
      padding: 0;
    }
    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
    }

    input[type="checkbox"] + span {
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        padding: 6px 10px;
        font-size: 14px;
        border: 1px solid rgb(215, 215, 215);
        border-radius: 5px;
        font-weight: 400;
        background-color: white;
    }

    input[type="checkbox"]:checked + span {
        border-color: #67C23A;
        background-color: #67C23A;
        color: white;
    }
</style>

<style>
  .el-input-group__append, .el-input-group__prepend{
    padding: 0 !important;
  }
</style>

<script>
  import axios from 'axios'
  import SIdentify from '@/components/SIdentify'

  export default {
      components: {SIdentify},
      name:'Register',
      data() {
          var check_yz_code = (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请输入验证码'));
            } else if (value !== this.identifyCode) {
              callback(new Error('验证码错误!'));
            } else {
              callback();
            }
          };
          var check_limit = (rule, value, callback) => {
            if (value.length !== 2 && this.form.iflimit === true && this.form.ifallow === true) {
              callback(new Error('请选择时间段'));
            } else {
              callback();
            }
          };
          var check_jiezhi_time = (rule, value, callback) => {
            if (value === '' && this.form.iflimit === false && this.form.ifallow === true) {
              callback(new Error('请选择截止时间'));
            } else {
              callback();
            }
          };
          var check_limit_edit = (rule, value, callback) => {
            if (value.length !== 2 && this.edit_digital_array.iflimit === true && this.edit_digital_array.ifallow === true) {
              callback(new Error('请选择时间段'));
            } else {
              callback();
            }
          };
          var check_jiezhi_time_edit = (rule, value, callback) => {
            if (value === '' && this.edit_digital_array.iflimit === false && this.edit_digital_array.ifallow === true) {
              callback(new Error('请选择截止时间'));
            } else {
              callback();
            }
          };
          return {
            loading: true,
            identifyCode: '',
            identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
            checkAll: false,
            dialogVisible: false,
            show_digital_dialog: false,
            show_edit_dialog: false,
            share_link_dialog: false,
            show_digital_array: {},
            edit_digital_array: {
              peoples:[]
            },
            people_list: [],
            tableData: [],

            create_width: '50%',

            now_request_url: window.location.origin,

            disabled: true,

            pickerOptions: {
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
            },

            form: {
              title: '',
              ifallow: false,
              ifouttime: true,
              iflimit: false,
              limit_time: [],
              peoples:[],
              yz_code:'',
              jiezhi_time:''
            },

            rules: {
              title: [
                { required: true, message: '请输入登记表标题', trigger: 'blur' }
              ],
              limit_time:[
                { required: true,validator: check_limit, trigger: 'change'}
              ],
              yz_code:[
                { required: true, validator: check_yz_code, trigger: 'blur'}
              ],
              jiezhi_time:[
                { required: true, validator: check_jiezhi_time, trigger: 'change' }
              ],
              peoples: [
                { type: 'array', required: true, message: '请至少选择一名应完成人员', trigger: 'change' }
              ]
            },
            rules_edit: {
              title: [
                { required: true, message: '请输入登记表标题', trigger: 'blur' }
              ],
              limit_time:[
                { required: true,validator: check_limit_edit, trigger: 'change'}
              ],
              jiezhi_time:[
                { required: true, validator: check_jiezhi_time_edit, trigger: 'change' }
              ],
              peoples: [
                { type: 'array', required: true, message: '请至少选择一名应完成人员', trigger: 'change' }
              ]
            },
          
            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.$refs['form'].resetFields();
              this.checkAll = false;
              this.dialogVisible = false;
              done();
            })
            .catch(_ => {});
        },
        handleClose_edit(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.edit_digital_array = {
                peoples:[]
              };
              this.show_edit_dialog = false;
              done();
            })
            .catch(_ => {});
        },
        handleShow(row) {
          this.show_digital_array = row
          this.show_digital_dialog = true
        },
        handleEdit(row){
          this.edit_digital_array = JSON.parse(JSON.stringify(row))
          this.show_edit_dialog = true
        },
        handleShare(row){
          this.show_digital_array = row
          this.share_link_dialog = true
        },
        onSubmit(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.form.peoples.sort((a, b) => parseInt(a) - parseInt(b));
              let peopleArraytoString=this.form.peoples.join(",");
              let data = new FormData();
              data.append('title', this.form.title)
              data.append('ifallow', this.form.ifallow)
              data.append('jiezhi_time', this.form.jiezhi_time)
              data.append('ifouttime', this.form.ifouttime)
              data.append('iflimit', this.form.iflimit)
              data.append('start_time', this.form.limit_time[0])
              data.append('end_time', this.form.limit_time[1])
              data.append('people', peopleArraytoString)

              this.axios({
                method:"post",
                url: '/api/RegisterController.php?method=create',
                data: data,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
                  
                  if(res.data.code == 200) {
                    this.tableData.unshift(res.data.new_data);
                    this.$message.success('添加成功！');
                    this.$refs[formName].resetFields();
                    this.dialogVisible = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        onSubmitToEdit(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              let data = new FormData();
              data.append('id', this.edit_digital_array.id)
              data.append('title', this.edit_digital_array.title)
              data.append('ifallow', this.edit_digital_array.ifallow)
              data.append('jiezhi_time', this.edit_digital_array.jiezhi_time)
              data.append('ifouttime', this.edit_digital_array.ifouttime)
              data.append('iflimit', this.edit_digital_array.iflimit)
              data.append('start_time', this.edit_digital_array.limit_time[0])
              data.append('end_time', this.edit_digital_array.limit_time[1])

              this.axios({
                method:"post",
                url: '/api/RegisterController.php?method=edit',
                data:data,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                  if(res.data.code == 200) {
                    for(var i=0;i<this.tableData.length;i++) {
                      if(this.tableData[i].id == this.edit_digital_array.id) {
                        this.tableData[i] = JSON.parse(JSON.stringify(this.edit_digital_array))
                        break;
                      }
                    }
                    this.$message.success('修改成功！');
                    this.$refs[formName].resetFields();
                    this.show_edit_dialog = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        handleDel(formdata) {
          this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.get('/api/RegisterController.php',{params:{method:'del', id:formdata.id}})
              .then(res => {
                if(res.data.code != null && res.data.code == 400) {
                  this.$message.error('登录状态过期，请重新登录');
                  localStorage.clear('name');
                  localStorage.clear('isLogin');
                  this.$router.push('/login?refer=' + window.location.pathname)
                }
                else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                if(res.data.code == 200) {
                  this.$message({
                    type: 'success',
                    message: '删除记录：“'+formdata.title+'”成功!'
                  });
                  for(var i=0;i<this.tableData.length;i++) {
                    if(this.tableData[i].id == formdata.id) {
                      this.tableData.splice(i, 1);
                    }
                  }
                } else {
                  this.$message.error('失败：' + res.data.msg)
                }
              }).catch(err => {
                console.log(err)
              })
          })
        },
        handleCheckAll(event) {
          if(event.target.checked) {
            this.form.peoples = this.people_list.map(item => item.id);
          } else {
            this.form.peoples = []
          }
        },
        handleCheckedColumn(value) {
          if(value.length === this.people_list.length) {
            this.checkAll = true;
          } else {
            this.checkAll = false;
          }
        },
        refreshCode () {
            this.identifyCode = ''
            this.makeCode(this.identifyCodes,4);
        },
        makeCode (o,l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
            }
        },
        randomNum (min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        toDataPanel(id) {
          this.$router.push('/user/showdata/'+id)
        },
        getRegisterList() {
          axios.get('/api/getRegisterList.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              
              this.people_list = res.data.people_list
              this.tableData = res.data.result
            
              this.pageCount = res.data.result.length
              this.loading = false

            }).catch(err => {
              console.log(err)
            })
        },
        handleResize() {
          if (window.innerWidth < 992) {
            this.create_width = "100%"
          } else {
            this.create_width = "50%"
          }
        }
        
      },
      mounted(){
        this.identifyCode = ''
        this.makeCode(this.identifyCodes,4)

        this.handleResize()
        window.addEventListener("resize", this.handleResize);
      },
      created() {
        this.loading = false
        this.getRegisterList()
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
      }
  };
</script>
