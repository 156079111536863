<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>素拓申报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <div class="tip">
        政策文件：<a target="_blank" href="https://class.ljcljc.cn/public/file/2023年计算机科学与数学学院学生素质综合测评加分细则.docx">《2023年计算机科学与数学学院学生素质综合测评加分细则》</a>、<a target="_blank" href="https://class.ljcljc.cn/public/file/2023年度福建理工大学学生创新创业竞赛等级分类一览表.doc">《2023年度福建理工大学学生创新创业竞赛等级分类一览表》</a>
      </div>
      <div>
        <div style="display: flex; align-items: center; margin-bottom: 10px;">
          <el-button type="primary" @click="createNewReport" style="margin-right: 5px;">新增</el-button>
        </div>
        <el-form label-position="left" label-width="100px">
          <el-form-item label="年度：">
            <el-select v-model="recordYear" placeholder="请选择">
              <el-option
                  v-for="item in shenbaoYearOptions"
                  :key="item.id"
                  :label="item.competitionYear"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <span style="margin-top: 5px;font-size: 15px">申报总分：<strong>{{ totalScore }}</strong> 分&nbsp;&nbsp;通过总分：<strong>{{
              totalScoreSuccess
            }}</strong> 分</span><br>


        </el-form>
        <el-table :loading="loading" :data="compRecords.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%;">
          <el-table-column prop="unid" label="序号" width="80"></el-table-column>
          <el-table-column prop="title" label="名称" min-width="180" show-overflow-tooltip></el-table-column>
          <el-table-column prop="leixing" label="类型" min-width="180"></el-table-column>
          <el-table-column prop="cur_class" label="等级" min-width="180"></el-table-column>
          <el-table-column prop="cur_rank" label="获奖情况" min-width="180"></el-table-column>
          <el-table-column prop="score" label="获得素拓分" min-width="180"></el-table-column>
          <el-table-column label="操作" min-width="180">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="showDetail(scope.row)" plain>查看详情</el-button>
              <el-button type="danger" size="mini" @click="rollback(scope.row)" plain v-if="scope.row.type !== '4'">撤回</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :pager-count="7"
            :current-page="currentPage"
            :page-sizes="[15,20,30,50]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="compRecords.length > 0 ? compRecords.length : null"
            background
            style="float: right; margin-top: 20px"
        >
        </el-pagination>
        <el-dialog title="详情" :visible.sync="show_digital_dialog" :width="create_width">
          <el-steps :active="shenhe_step" finish-status="success">
            <el-step title="申报" :description="show_digital_array.declarerName + '（已完成）'"></el-step>
            <el-step title="审核" :description="shenheDesc"></el-step>
            <el-step title="审核结果" :description="shenheResult"></el-step>
          </el-steps>
          <br>
          <el-descriptions title="详细信息" :column="show_digital_array.type === '1' ? 1 : 2" border style="font-size: 14px">
            <el-descriptions-item label="名称">{{ show_digital_array.title }}</el-descriptions-item>
            <el-descriptions-item label="类型">{{ show_digital_array.leixing }}</el-descriptions-item>
            <el-descriptions-item label="比赛性质" v-if="show_digital_array.type === '1'">
              {{ show_digital_array.compXingzhi }}
            </el-descriptions-item>
            <el-descriptions-item label="主办方" v-if="show_digital_array.type === '1'">{{
                show_digital_array.compOrg
              }}
            </el-descriptions-item>
            <el-descriptions-item label="比赛级别" v-if="show_digital_array.type === '1'">
              {{ show_digital_array.compLevel }}
            </el-descriptions-item>
            <el-descriptions-item label="获奖等级" v-if="show_digital_array.type !== '5'">
              {{ show_digital_array.compClass }}
            </el-descriptions-item>
            <el-descriptions-item label="获奖情况"
                                  v-if="show_digital_array.type !== '3' && show_digital_array.type !== '5'">
              {{ show_digital_array.compRank }}
            </el-descriptions-item>
            <el-descriptions-item label="预计获得素拓分" v-if="show_digital_array.status === '1'">
              {{ show_digital_array.compScore }}
            </el-descriptions-item>
            <el-descriptions-item label="获得素拓分" v-if="show_digital_array.status === '2'">
              {{ show_digital_array.compScore }}
            </el-descriptions-item>
            <el-descriptions-item label="证明材料">
              <el-button type="primary" size="mini" @click="downloadFile(show_digital_array.compProofURL)">点击下载
              </el-button>
            </el-descriptions-item>

          </el-descriptions>


          <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="show_digital_dialog = false">确 定</el-button>
                  </span>
        </el-dialog>

        <el-dialog
            title="新增申请"
            :visible.sync="dialogVisible"
            :width="create_width"
            :before-close="handleClose">
          <el-form ref="form" :model="form" :rules="rules" label-width="130px">
            <el-form-item label="申报年份" prop="shenbaoYearValue">
              <el-select v-model="form.shenbaoYearValue" filterable placeholder="请选择">
                <el-option
                    v-for="item in shenbaoYearOptions"
                    :key="item.id"
                    :label="item.competitionYear"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申报类型" prop="shenbaoValue">
              <el-select v-model="form.shenbaoValue" filterable placeholder="请选择">
                <el-option
                    v-for="item in shenbaoOptions"
                    :key="item.id"
                    :label="item.competitionType"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="比赛名称" prop="compChose" v-if="form.shenbaoValue === '1'">
              <el-select v-model="form.compChose" filterable @change="compChoseAction" placeholder="请选择">
                <el-option
                    v-for="item in compList"
                    :key="item.id"
                    :label="item.cmptName"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="比赛性质" prop="compXingzhi" v-if="form.shenbaoValue === '1'">
              <el-select v-model="form.compXingzhi" @change="getCurrentPredictScore" placeholder="请选择">
                <el-option key="1" label="个人项目" value="1"></el-option>
                <el-option key="2" label="团队项目" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主办方" prop="compOrg" v-if="form.shenbaoValue === '1'">
              <el-input v-model="compOrg" readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="荣誉名称" prop="compPersonChose" v-if="form.shenbaoValue === '3'">
              <el-select v-model="form.compPersonChose" @change="getCurrentPredictScore" filterable
                         placeholder="请选择">
                <el-option
                    v-for="item in compPersonList"
                    :key="item.id"
                    :label="item.honorName"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="compOtherChose" v-if="form.shenbaoValue === '5'">
              <el-select v-model="form.compOtherChose" @change="getCurrentPredictScore" filterable placeholder="请选择">
                <el-option
                    v-for="item in compOtherList"
                    :key="item.id"
                    :label="item.competitionName"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文体活动名称" prop="comptWentiTitle" v-if="form.shenbaoValue === '2'">
              <el-input v-model="form.comptWentiTitle"></el-input>
            </el-form-item>
            <el-form-item label="比赛级别" prop="compLevel" v-if="form.shenbaoValue === '1'">
              <el-input v-model="compLevel" readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="获奖等级" prop="compClass"
                          v-if="form.shenbaoValue === '1' || form.shenbaoValue === '2' || form.shenbaoValue === '3'">
              <el-select v-model="form.compClass" @change="getCurrentPredictScore" placeholder="请选择">
                <el-option
                    v-for="item in compClassList"
                    :key="item.id"
                    :label="item.competitionClass"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="获奖情况" prop="compRank"
                          v-if="form.shenbaoValue === '1' || form.shenbaoValue === '2'">
              <el-select v-model="form.compRank" @change="getCurrentPredictScore" placeholder="请选择">
                <el-option
                    v-for="item in compRankList"
                    :key="item.id"
                    :label="item.competitionRank"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="考核等级" prop="compKaohe_level"
                          v-if="form.shenbaoValue === '6'">
              <el-select v-model="form.compKaohe_level" @change="getCurrentPredictScore" placeholder="请选择">
                <el-option
                    v-for="item in compKaoheLevelList"
                    :key="item.id"
                    :label="item.compKaoheLevel"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="干部类别" prop="compKaohe_class"
                          v-if="form.shenbaoValue === '6'">
              <el-select v-model="form.compKaohe_class" @change="getCurrentPredictScore" placeholder="请选择">
                <el-option
                    v-for="item in compKaoheClassList"
                    :key="item.id"
                    :label="item.compKaoheClass"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" v-if="form.shenbaoValue === '4'">
              <span style="color: red">集体荣誉统一导入无需手动添加</span>
            </el-form-item>
            <el-form-item label="预计获得素拓分" prop="compScore" v-if="form.shenbaoValue !== '4'">
              <el-input v-model="form.compScore" readonly="true"></el-input>
            </el-form-item>
            <el-form-item label="证明材料上传" prop="fileList_1" v-if="form.shenbaoValue !== '4'">
              <el-upload
                  class="upload-demo"
                  ref="upload_1"
                  action=""
                  :before-upload="beforeUpload_1"
                  :on-change="handleChange_1"
                  :on-remove="handleRemove_1"
                  :on-exceed="handleExceed_1"
                  :file-list="form.fileList_1"
                  accept=".png, .jpeg, .jpg, .pdf"
                  :multiple="false"
                  :limit="1"
                  :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png/pdf文件，且不超过8MB</div>
              </el-upload>
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('form')"  v-if="form.shenbaoValue !== '4'">提交审核</el-button>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
          </span>
        </el-dialog>
      </div>
    </el-main>
  </el-main>
</template>

<style scoped>
.el-select {
  width: 100%;
}
.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 0 0 5px 0;
}

</style>

<script>
import axios from 'axios'
import SIdentify from "@/components/SIdentify.vue";

export default {
  name: 'VoteData',
  components: {SIdentify},
  data() {
    return {
      loading: false,
      compRecords: [],
      shenbaoOptions: [],
      shenbaoYearOptions: [],
      compList: [],
      compOrg: '',
      compLevel: '',
      compLevelList: [],
      compClassList: [],
      compRankList: [],
      compPersonList: [],
      compOtherList: [],
      compKaoheLevelList: [],
      compKaoheClassList: [],
      show_digital_array: {},

      recordYear: '1',
      totalScore: 0,
      totalScoreSuccess: 0,
      monitor: '',
      shenhe_step: 1,

      form: {
        shenbaoYearValue: '1',
        shenbaoValue: '',
        compChose: '',
        compXingzhi: '',
        compPersonChose: '',
        compOtherChose: '',
        comptWentiTitle: '',
        compClass: '',
        compRank: '',
        compKaohe_level: '',
        compKaohe_class: '',
        compScore: '',
        fileList_1: []
      },

      shenheDesc: '',
      shenheResult: '通过/驳回',


      rules: {
        shenbaoYearValue: [
          {required: true, message: '请选择申报年份', trigger: 'change'}
        ],
        shenbaoValue: [
          {required: true, message: '请选择申报类型', trigger: 'change'}
        ],
        compChose: [
          {required: true, message: '请选择比赛名称', trigger: 'change'}
        ],
        compXingzhi: [
          {required: true, message: '请选择比赛性质', trigger: 'change'}
        ],
        compPersonChose: [
          {required: true, message: '请选择荣誉名称', trigger: 'change'}
        ],
        compOtherChose: [
          {required: true, message: '请选择名称', trigger: 'change'}
        ],
        comptWentiTitle: [
          {required: true, message: '请填写文体活动名称', trigger: 'blur'}
        ],
        compRank: [
          {required: true, message: '请选择获奖情况', trigger: 'change'}
        ],
        compClass: [
          {required: true, message: '请选择获奖等级', trigger: 'change'}
        ],
        compKaohe_level: [
          {required: true, message: '请选择考核等级', trigger: 'change'}
        ],
        compKaohe_class: [
          {required: true, message: '请选择干部类别', trigger: 'change'}
        ],
        fileList_1: [
          {required: true, message: '请上传证明材料', trigger: 'change'}
        ]
      },

      dialogVisible: false,
      show_digital_dialog: false,
      create_width: '50%',


      currentPage: 1,
      pagesize: 20,
      pageCount: 0
    }
  },
  methods: {
    beforeUpload_1(file) {
      let size = file.size / 1024 / 1024;
      if (size > 8) {
        this.$message.warning("文件大小不得超过8M");
      }
    },
    handleChange_1(file, fileList_1) {
      if (file) {
        this.form.fileList_1 = fileList_1.slice(-3);
      }
    },
    handleRemove_1(file, fileList_1) {
      this.form.fileList_1 = [];
    },
    handleExceed_1(files, fileList_1) {
      this.$message.warning(
          '只能选择1个文件，如果要重新选择文件，请先在上传列表中删除原来的文件'
      );
    },
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    createNewReport() {
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.$refs['form'].resetFields();
            this.checkAll = false;
            this.isALL = false;
            this.dialogVisible = false;
            done();
          })
          .catch(_ => {
          });
    },
    compChoseAction() {
      for (let i = 0; i < this.compList.length; i++) {
        if (this.compList[i].id == this.form.compChose) {
          this.compOrg = this.compList[i].cmptOrg;
          this.compLevel = this.compList[i].cmptLevel;
          for (let j = 0; j < this.compLevelList.length; j++) {
            if (this.compLevelList[j].id == this.compLevel) {
              this.compLevel = this.compLevelList[j].cmptLevel;
              break;
            }
          }
          break;
        }
      }
      this.getCurrentPredictScore()
    },
    loadRecords() {
      this.loading = true
      this.compRecords = []
      this.totalScore = 0
      this.totalScoreSuccess = 0
      axios.get('/api/CompController.php?method=getRecords&year=' + this.recordYear)
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null) this.$message.error(res.data.msg);

            const records = res.data.data.records;

            for (let i = 0; i < records.length; i++) {
              let cmptLeixing = '';
              let cur_class = '';
              let cur_rank = '';

              for (let j = 0; j < this.shenbaoOptions.length; j++) {
                if (records[i].competitionType == this.shenbaoOptions[j].id) {
                  cmptLeixing = this.shenbaoOptions[j].competitionType;
                  break;
                }
              }

              for (let j = 0; j < this.compClassList.length; j++) {
                if (records[i].competitionClass == this.compClassList[j].id) {
                  cur_class = this.compClassList[j].competitionClass;
                  break;
                }
              }

              for (let j = 0; j < this.compRankList.length; j++) {
                if (records[i].competitionRank == this.compRankList[j].id) {
                  cur_rank = this.compRankList[j].competitionRank;
                  break;
                }
              }

              let cur_score = records[i].competitionScore
              let data = {
                id: records[i].id,
                score: cur_score,
                unid: i + 1,
                title: records[i].competitionName,
                cmptId: records[i].competitionId,
                leixing: cmptLeixing,
                type: records[i].competitionType,
                cur_class: cur_class,
                cur_rank: cur_rank,
                rank: records[i].competitionRank,
                status: records[i].competitionStatus,
                proofURL: records[i].competitionProofURL,
                competitionClass: records[i].competitionClass,
                declarerName: records[i].declarerName,
                competitionXingzhi: records[i].competitionXingzhi
              }

              this.compRecords.push(data);
            }
            this.totalScore =  res.data.data.totalScore;
            this.totalScoreSuccess = res.data.data.totalSuccessScore;

            this.pageCount = this.compRecords.length

            this.totalScore = this.totalScore.toFixed(2);
            this.totalScoreSuccess = this.totalScoreSuccess.toFixed(2);
          })

      this.loading = false
    },
    getData() {
      axios.get('/api/CompController.php?method=get')
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null) this.$message.error(res.data.msg);

            const data = res.data.data;
            this.compClassList = data.competition_classes;
            this.compRankList = data.competition_ranks;
            this.compList = data.competition_lists;
            this.compOtherList = data.competition_others;
            this.compPersonList = data.competition_personalhonors;
            this.compLevelList = data.competition_levels;
            this.shenbaoYearOptions = data.competition_years;
            this.shenbaoOptions = data.competition_types;
            this.compKaoheLevelList = data.competition_kaoheLevels;
            this.compKaoheClassList = data.competition_kaoheClasses;
            this.monitor = data.monitor;

            this.loadRecords();


          }).catch(err => {
        console.log(err)
      })
    },
    showDetail(row) {
      let cur_compOrg = '';
      let cur_compLevel = '';
      if (row.type === '1') {
        for (let i = 0; i < this.compList.length; i++) {
          if (this.compList[i].id === row.cmptId) {
            cur_compOrg = this.compList[i].cmptOrg;
            cur_compLevel = this.compList[i].cmptLevel;
            for (let j = 0; j < this.compLevelList.length; j++) {
              if (this.compLevelList[j].id === cur_compLevel) {
                cur_compLevel = this.compLevelList[j].cmptLevel;
                break;
              }
            }
          }
        }
      }
      let cur_compRank = '';
      for (let i = 0; i < this.compRankList.length; i++) {
        if (this.compRankList[i].id === row.rank) {
          cur_compRank = this.compRankList[i].competitionRank;
          break;
        }
      }
      let cur_compClass = '';
      for (let i = 0; i < this.compClassList.length; i++) {
        if (this.compClassList[i].id === row.competitionClass) {
          cur_compClass = this.compClassList[i].competitionClass;
          break;
        }
      }

      this.show_digital_array = {
        title: row.title,
        leixing: row.leixing,
        type: row.type,
        compXingzhi: row.competitionXingzhi,
        compOrg: cur_compOrg,
        compLevel: cur_compLevel,
        compRank: cur_compRank,
        compClass: cur_compClass,
        status: row.status,
        compScore: row.score,
        compProofURL: row.proofURL,
        declarerName: row.declarerName
      }
      if (this.show_digital_array.status === '1') {
        this.shenheDesc = this.monitor + "（等待审核）";
        this.shenheResult = "通过/驳回";
        this.shenhe_step = 1;
      }
      if (this.show_digital_array.status === '2') {
        this.shenheDesc = this.monitor + "（已完成）";
        this.shenheResult = "通过";
        this.shenhe_step = 3;
      }
      if (this.show_digital_array.status === '3') {
        this.shenheDesc = this.monitor + "（已完成）";
        this.shenheResult = "驳回";
        this.shenhe_step = 2;
      }

      this.show_digital_dialog = true;
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.create_width = "100%"
      } else {
        this.create_width = "50%"
      }
    },
    getCurrentPredictScore() {
      if (this.form.shenbaoValue === '1') {
        if (this.form.compChose === '' || this.form.compXingzhi === '' || this.form.compRank === '' || this.form.compClass === '')
          return false;
      } else if (this.form.shenbaoValue === '2') {
        if (this.form.compRank === '' || this.form.compClass === '')
          return false;
      } else if (this.form.shenbaoValue === '3') {
        if (this.form.compPersonChose === '' || this.form.compClass === '')
          return false;
      } else if (this.form.shenbaoValue === '5') {
        if (this.form.compOtherChose === '')
          return false;
      } else if(this.form.shenbaoValue === '6') {
        if (this.form.compKaohe_level === '' || this.form.compKaohe_class === '')
          return false;
      }
      let data = {
        shenbaoValue: this.form.shenbaoValue,
        compChose: this.form.compChose,
        compXingzhi: this.form.compXingzhi,
        compPersonChose: this.form.compPersonChose,
        compOtherChose: this.form.compOtherChose,
        compRank: this.form.compRank,
        compClass: this.form.compClass,
        compKaoheLevel: this.form.compKaohe_level,
        compKaoheClass: this.form.compKaohe_class
      }
      this.axios({
        method: "post",
        url: '/api/CompController.php?method=getPredictScore',
        data: data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null) this.$message.error(res.data.msg);

            this.form.compScore = res.data.data;
          })
    },
    downloadFile(url) {
      this.$message.success('成功发起下载请求，请耐心等待文件下载完成');
      let a = document.createElement('a');
      a.href = '/public/sutuo' + url;
      a.target = '_blank';
      a.click();
    },
    onSubmit(formName) {
      if (this.form.fileList_1.length === 0) {
        this.$message.warning("请选择证明材料");
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = new FormData();

          data.append('shenbaoYearValue', this.form.shenbaoYearValue);
          data.append('shenbaoValue', this.form.shenbaoValue);
          data.append('compChose', this.form.compChose);
          data.append('compXingzhi', this.form.compXingzhi);
          data.append('compPersonChose', this.form.compPersonChose);
          data.append('compOtherChose', this.form.compOtherChose);
          data.append('comptWentiTitle', this.form.comptWentiTitle);
          data.append('compClass', this.form.compClass);
          data.append('compRank', this.form.compRank);
          data.append('compScore', this.form.compScore);
          data.append('compKaoheLevel', this.form.compKaohe_level);
          data.append('compKaoheClass', this.form.compKaohe_class);
          data.append('file1', this.form.fileList_1[0].raw);


          this.axios({
            method: "post",
            url: '/api/CompController.php?method=upload',
            data: data,
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
              .then(res => {
                if (res.data.code != null && res.data.code == 400) {
                  this.$message.error('登录状态过期，请重新登录');
                  localStorage.clear('name');
                  localStorage.clear('isLogin');
                  this.$router.push('/login?refer=' + window.location.pathname)
                } else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                if (res.data.code == 200) {
                  this.$message.success('已提交申请！');
                  this.$refs[formName].resetFields();
                  this.dialogVisible = false;
                  this.loadRecords();
                }
              }).catch(err => {
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    rollback(row) {
      this.$confirm('是否确定撤回该申请？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.get('/api/CompController.php?method=rollback&id=' + row.id)
            .then(res => {
              if (res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              } else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

              if (res.data.code == 200) {
                this.$message.success('撤回成功！');
                this.loadRecords();
              }
            })
      })

    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true;
    this.getData();

  },
  computed: {
    ListArr() {
      return this.tableData.filter(function (item) {
        return item.finish_time === ''
      })
    },
    sortedArray() {
      return this.tableData.sort((a, b) => a.id - b.id)
    }
  }
};
</script>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>
