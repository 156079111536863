import { render, staticRenderFns } from "./Liuyan.vue?vue&type=template&id=7a51029c&scoped=true&"
import script from "./Liuyan.vue?vue&type=script&lang=js&"
export * from "./Liuyan.vue?vue&type=script&lang=js&"
import style0 from "./Liuyan.vue?vue&type=style&index=0&id=7a51029c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a51029c",
  null
  
)

export default component.exports