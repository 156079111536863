<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>收集表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-button type="primary" @click="dialogVisible = true" plain>创建收集表</el-button>
      <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
        style="width: 100%">
        <el-table-column prop="id" label="收集表ID" min-width="80px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="title" :show-overflow-tooltip="true" label="收集表标题" min-width="200px">
          <template slot-scope="scope">
            {{ scope.row.title }}
            <span v-show="scope.row.ifshenhe === true" class="shenhe_class">审</span>
            <span v-show="scope.row.isneed === false" class="need_class">选</span>
          </template>
        </el-table-column>
        <el-table-column prop="people_num" label="应完成人数" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="people_num_finish" label="已完成人数" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="poster" label="创建人" min-width="80px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="time" label="创建时间" min-width="160px" header-align="center" align="center"></el-table-column>
        <el-table-column label="操作" width="340px" header-align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="toDataPanel(scope.row.id)">查看数据</el-button>
            <el-button size="mini" type="success" @click="handleShare(scope.row)">分享链接</el-button>
            <el-button size="mini" type="info" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="tableData.length > 0 ? tableData.length : null"
        background style="float: right; margin-top: 20px">
      </el-pagination>
      <el-dialog title="创建收集表" :visible.sync="dialogVisible" :width="create_width" :before-close="handleClose">
        <el-form ref="form" status-icon :model="form" :rules="rules" label-width="160px">
          <el-form-item label="收集表标题" prop="title">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="自动重命名文件" prop="ifrename">
            <el-switch v-model="form.ifrename"></el-switch>
          </el-form-item>
          <el-form-item label="文件命名格式规则" prop="rename" v-if="form.ifrename === true">
            <el-input v-model="form.rename" placeholder="填写前请注意看下方提示"></el-input>
            <br><span>提供四种变量：{name}、{classid}、{id}、{id-plus}，其中{name}表示姓名，{classid}表示学号，{id}表示不补前置0的号数，{id-plus}表示补前置0的号数（若不足2位则补前置0）。
              <br>例如：想要把文件名自动重命名成：软工2201姓名-号数，则需输入：软工2201{name}-{id}
              <br>特别注意，{}（是花括号不是括号）和name、classid、id之间没有空格！</span><br>
            <span style="color:red;">特别注意：文件命名格式规则创建后无法修改</span>
          </el-form-item>
          <el-form-item label="每个人单独创建文件夹" prop="if_folder">
            <el-switch v-model="form.if_folder"></el-switch>
          </el-form-item>
          <el-form-item label="文件夹命名格式规则" prop="folder_name" v-if="form.if_folder === true">
            <el-input v-model="form.folder_name" placeholder="填写前请注意看下方提示"></el-input>
            <br><span>提供四种变量：{name}、{classid}、{id}、{id-plus}，其中{name}表示姓名，{classid}表示学号，{id}表示不补前置0的号数，{id-plus}表示补前置0的号数（若不足2位则补前置0）。
              <br>例如：想要把文件名自动重命名成：软工2201姓名-号数，则需输入：软工2201{name}-{id}
              <br>特别注意，{}（是花括号不是括号）和name、classid、id之间没有空格！</span><br>
            <span style="color:red;">特别注意：文件夹命名规则创建后无法修改</span>
          </el-form-item>
          <el-form-item label="允许上传文件的类型" prop="allow_format">
            <el-checkbox-group v-model="form.allow_format">
              <el-checkbox label="doc">Word文档
                <el-tooltip class="item" effect="dark" content="后缀为.doc, .docx" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="ppt">PPT演示文稿
                <el-tooltip class="item" effect="dark" content="后缀为.ppt,.pptx" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="xls">Excel表格
                <el-tooltip class="item" effect="dark" content="后缀为.xls,.xlsx" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="pdf">PDF文件
                <el-tooltip class="item" effect="dark" content="后缀为.pdf" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="png">图片格式
                <el-tooltip class="item" effect="dark" content="后缀为.png,.jpg,.jpeg" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="zip">压缩文件
                <el-tooltip class="item" effect="dark" content="后缀为.zip,.rar,.7z,.tar.gz" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
            </el-checkbox-group>
            <div style="color: red; float: left;">暂时仅支持以上文件类型</div><br>
            <div style="color: red; float: left;">注意：尽量不要同时允许图片类型和其他类型的文件，因为若同时允许图片和其他类型的文件，在微信小程序端只能上传图片，网页端不受影响。</div>
          </el-form-item>
          <el-form-item label="收集表公告" prop="sj_notice">
            <el-input type="textarea" :autosize="{ minRows: 5 }" v-model="form.sj_notice"></el-input>
            <br><span>
              <div style="color: red; float: left;">支持HTML代码，不支持script标签，请不要尝试注入JavaScript代码！！</div>&nbsp;<a
                href="https://www.runoob.com/html/html-tutorial.html" target='_blank'>快速入门</a><br>
              <div style="color: red; float: left;">注意：如果使用了HTML代码，在微信小程序中可能会无法正常显示，这是由于微信小程序对富媒体渲染能力较差，所以不推荐使用HTML代码</div>
            </span>
            <br><el-button type="primary" size="mini" @click="HTMLPreview">预览</el-button>&nbsp;<el-button type="danger"
              size="mini" @click="useTemplate">使用HTML模板</el-button>
          </el-form-item>
          <el-form-item label="固定时间模式" prop="iflimit">
            <el-switch v-model="form.iflimit"></el-switch>
            <el-tooltip class="item" effect="dark" content="开启后可以设置固定时间段开放提交" placement="top">
              <i class="el-icon-info" style="font-size: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="开放固定时间段" prop="limit_time" v-if="form.iflimit === true">
            <el-date-picker v-model="form.limit_time" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="截止时间" prop="jiezhi_time" v-if="form.iflimit === false">
            <el-date-picker v-model="form.jiezhi_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="选择时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="允许截止时间过后提交" prop="ifouttime" v-if="form.iflimit === false">
            <el-switch v-model="form.ifouttime"></el-switch>
          </el-form-item>
          <el-form-item label="展示提交排行榜" prop="ifrank">
            <el-switch v-model="form.ifrank"></el-switch>
            <el-tooltip class="item" effect="dark" content="是否让用户查看提交排行榜" placement="top">
              <i class="el-icon-info" style="font-size: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="是否需要审核" prop="ifrank">
            <el-switch v-model="form.ifshenhe"></el-switch>
            <el-tooltip class="item" effect="dark" content="对于某些文件需要核对的，管理员可以进行审核并返回审核状态" placement="top">
              <i class="el-icon-info" style="font-size: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="是否为必须完成" prop="isneed">
            <el-switch v-model="form.isneed"></el-switch>
            <el-tooltip class="item" effect="dark" content="若为非必须完成，则首页饼图中不会显示此条数据" placement="top">
              <i class="el-icon-info" style="font-size: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="应完成人员" prop="peoples">
            <label>
              <input type="checkbox" v-model="checkAll" @change="handleCheckAll">
              <span :class="{ 'is-checked': checkAll }"><span>全选</span></span>
            </label><br>
            <label v-for="item in people_list" :key="item.id">
              <input type="checkbox" v-model="form.peoples" :value="item.id" @change="handleCheckedColumn">
              <span :class="{ 'is-checked': form.peoples.indexOf(item.id) > -1 }"><span>{{ item.name }}</span></span>
            </label><br>
            <span style="color:red;">特别注意：应完成人员创建后无法修改</span>
          </el-form-item>
          <el-form-item label="验证码" prop="yz_code">
            <el-input v-model="form.yz_code" placeholder="请输入验证码（不区分大小写）">
              <template slot="append">
                <div @click="refreshCode">
                  <s-identify :identifyCode="identifyCode"></s-identify>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')">创建</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog title="编辑" :visible.sync="show_edit_dialog" :width="create_width" :before-close="handleClose_edit">
        <el-form v-loading="edit_loading" element-loading-text="拼命加载中" ref="edit_form" status-icon
          :model="edit_digital_array" :rules="rules_edit" label-width="160px">
          <el-form-item label="收集表标题" prop="title">
            <el-input v-model="edit_digital_array.title"></el-input>
          </el-form-item>
          <el-form-item label="自动重命名文件" prop="ifrename">
            <el-switch v-model="edit_digital_array.ifrename" disabled></el-switch>
          </el-form-item>
          <el-form-item label="文件命名格式规则" prop="rename" v-if="edit_digital_array.ifrename == true">
            <el-input v-model="edit_digital_array.rename" placeholder="填写前请注意看下方提示" disabled></el-input>
          </el-form-item>
          <el-form-item label="每个人单独创建文件夹" prop="if_folder">
            <el-switch v-model="edit_digital_array.if_folder" disabled></el-switch>
          </el-form-item>
          <el-form-item label="文件夹命名格式规则" prop="folder_name" v-if="edit_digital_array.if_folder === true">
            <el-input v-model="edit_digital_array.folder_name" placeholder="填写前请注意看下方提示" disabled></el-input>
          </el-form-item>

          <el-form-item label="允许上传文件的类型" prop="allow_format">
            <el-checkbox-group v-model="edit_digital_array.allow_format">
              <el-checkbox label="doc">Word文档
                <el-tooltip class="item" effect="dark" content="后缀为.doc, .docx" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="ppt">PPT演示文稿
                <el-tooltip class="item" effect="dark" content="后缀为.ppt,.pptx" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="xls">Excel表格
                <el-tooltip class="item" effect="dark" content="后缀为.xls,.xlsx" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="pdf">PDF文件
                <el-tooltip class="item" effect="dark" content="后缀为.pdf" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="png">图片格式
                <el-tooltip class="item" effect="dark" content="后缀为.png,.jpg,.jpeg" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
              <el-checkbox label="zip">压缩文件
                <el-tooltip class="item" effect="dark" content="后缀为.zip,.rar,.7z,.tar.gz" placement="top">
                  <i class="el-icon-info" style="font-size: 15px;"></i>
                </el-tooltip>
              </el-checkbox>
            </el-checkbox-group>
            <font color="red">暂时仅支持以上文件类型</font>
          </el-form-item>
          <el-form-item label="收集表公告" prop="sj_notice">
            <el-input type="textarea" :autosize="{ minRows: 5 }" v-model="edit_digital_array.sj_notice"></el-input>
            <br><span>
              <font color="red">支持HTML代码，不支持script标签，请不要尝试注入JavaScript代码！！</font>&nbsp;<a
                href="https://www.runoob.com/html/html-tutorial.html" target='_blank'>快速入门</a>
            </span>
            <br><el-button type="primary" size="mini" @click="HTMLPreview">预览</el-button>&nbsp;<el-button type="danger"
              size="mini" @click="useTemplate">使用模板</el-button>
          </el-form-item>
          <el-form-item label="固定时间模式" prop="iflimit">
            <el-switch v-model="edit_digital_array.iflimit"></el-switch>
            <el-tooltip class="item" effect="dark" content="开启后可以设置固定时间段开放提交" placement="top">
              <i class="el-icon-info" style="font-size: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="开放固定时间段" prop="limit_time" v-if="edit_digital_array.iflimit === true">
            <el-date-picker v-model="edit_digital_array.limit_time" type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="截止时间" prop="jiezhi_time" v-if="edit_digital_array.iflimit === false">
            <el-date-picker v-model="edit_digital_array.jiezhi_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="选择时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="允许截止时间过后提交" prop="ifouttime" v-if="edit_digital_array.iflimit === false">
            <el-switch v-model="edit_digital_array.ifouttime"></el-switch>
          </el-form-item>
          <el-form-item label="展示提交排行榜" prop="ifrank">
            <el-switch v-model="edit_digital_array.ifrank"></el-switch>
            <el-tooltip class="item" effect="dark" content="是否让用户查看提交排行榜" placement="top">
              <i class="el-icon-info" style="font-size: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="是否需要审核" prop="ifrank">
            <el-switch v-model="edit_digital_array.ifshenhe"></el-switch>
            <el-tooltip class="item" effect="dark" content="对于某些文件需要核对的，管理员可以进行审核并返回审核状态" placement="top">
              <i class="el-icon-info" style="font-size: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="是否为必须完成" prop="isneed">
            <el-switch v-model="edit_digital_array.isneed"></el-switch>
            <el-tooltip class="item" effect="dark" content="若为非必须完成，则首页饼图中不会显示此条数据" placement="top">
              <i class="el-icon-info" style="font-size: 15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="应完成人员" prop="peoples">
            <span>此项不可修改</span><br>
            <label v-for="item in people_list" :key="item.id">
              <input type="checkbox" v-model="edit_digital_array.peoples" :value="item.id" :disabled="disabled">
              <span
                :class="{ 'is-checked': edit_digital_array.peoples.indexOf(item.id) > -1, 'disabled': disabled }"><span>{{ item.name }}</span></span>
            </label>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitToEdit('edit_form')">确认修改</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose_edit">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog title="分享链接" :visible.sync="share_link_dialog" :width="create_width">
        <span style="font-size:17px;">登记表ID：{{ show_digital_array.id }}</span><br>
        <span style="font-size:17px;">链接：{{now_request_url}}/user/collect_file/{{ show_digital_array.id }}</span><br>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="share_link_dialog = false">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="公告预览" :visible.sync="HTMLPreviewer" :width="create_width">
        <div style="font-size:20px;text-align:center;line-height:40px;">
          <h2>请上传 {{ dialogVisible === true ? form.title : show_edit_dialog === true ? edit_digital_array.title : null}}</h2>
          允许重复提交，重复提交版本将覆盖原始版本！<br>
          <p v-html="filteredHtml"></p><br>
          
          <span>姓名：XXX<br></span><br>
          <span>完成状态：<el-tag type="danger" size="medium" plain>未完成</el-tag></span><br><br>

          <el-button size="small" type="primary">提交文件</el-button>
          <el-button size="small" type="success">下载提交的文件</el-button>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="HTMLPreviewer = false">关 闭</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"]+span {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 5px;
  font-weight: 400;
  background-color: white;
}

input[type="checkbox"]:checked+span {
  border-color: #67C23A;
  background-color: #67C23A;
  color: white;
}

.shenhe_class {
  background-color: #fdf6ec;
  border: 1px solid #e6a23c;
  color: #e6a23c;
  font-size: 12px;
  border-radius: 3px;
  margin-right: 5px;
}

.need_class {
  background-color: #ecf3fd;
  border: 1px solid #3c80e6;
  color: #3c80e6;
  font-size: 12px;
  border-radius: 3px;
  margin-right: 3px;
}
</style>

<style>
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 !important;
}
</style>

<script>
import axios from 'axios'
import SIdentify from '@/components/SIdentify'
export default {
  components: { SIdentify },
  name: 'Collect',
  data() {
    var check_yz_code = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else if (value !== this.identifyCode) {
        callback(new Error('验证码错误!'));
      } else {
        callback();
      }
    };
    var check_rename = (rule, value, callback) => {
      if (value === '' && this.form.ifrename === true) {
        callback(new Error('请输入命名格式规则'));
      } else {
        callback();
      }
    };
    var check_folder_name = (rule, value, callback) => {
      if (value === '' && this.form.if_folder === true) {
        callback(new Error('请输入文件夹命名格式规则'));
      } else {
        callback();
      }
    };
    var check_limit = (rule, value, callback) => {
      if (value.length !== 2 && this.form.iflimit === true) {
        callback(new Error('请选择时间段'));
      } else {
        callback();
      }
    };
    var check_jiezhi_time = (rule, value, callback) => {
      if (value === '' && this.form.iflimit === false) {
        callback(new Error('请选择截止时间'));
      } else {
        callback();
      }
    };
    var check_limit_edit = (rule, value, callback) => {
      if (value.length !== 2 && this.edit_digital_array.iflimit === true) {
        callback(new Error('请选择时间段'));
      } else {
        callback();
      }
    };
    var check_jiezhi_time_edit = (rule, value, callback) => {
      if (value === '' && this.edit_digital_array.iflimit === false) {
        callback(new Error('请选择截止时间'));
      } else {
        callback();
      }
    };
    return {
      loading: true,
      edit_loading: false,
      identifyCode: '',
      identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
      checkAll: false,
      dialogVisible: false,
      show_digital_dialog: false,
      show_edit_dialog: false,
      share_link_dialog: false,
      HTMLPreviewer: false,
      show_digital_array: {},
      edit_digital_array: {
        title: '',
        ifrename: false,
        rename: '',
        allow_format: [],
        sj_notice: '',
        ifouttime: true,
        iflimit: false,
        limit_time: [],
        jiezhi_time: '',
        peoples: [],
        if_folder: false,
        folder_name: '',
        ifrank: true,
        ifshenhe: false,
        isneed: true
      },
      people_list: [],
      tableData: [],

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      disabled: true,
      now_request_url: window.location.origin,
      
      create_width: '60%',

      form: {
        title: '',
        ifrename: false,
        peoples: [],
        yz_code: '',
        jiezhi_time: '',
        allow_format: [],
        ifouttime: true,
        iflimit: false,
        limit_time: [],
        sj_notice: '',
        rename: '',
        if_folder: false,
        folder_name: '',
        ifrank: true,
        ifshenhe: false,
        isneed: true
      },

      rules: {
        title: [
          { required: true, message: '请输入登记表标题', trigger: 'blur' }
        ],
        yz_code: [
          { required: true, validator: check_yz_code, trigger: 'blur' }
        ],
        rename: [
          { required: true, validator: check_rename, trigger: 'blur' }
        ],
        folder_name: [
          { required: true, validator: check_folder_name, trigger: 'blur' }
        ],
        allow_format: [
          { type: 'array', required: true, message: '请至少一种文件类型', trigger: 'change' }
        ],
        limit_time: [
          { required: true, validator: check_limit, trigger: 'change' }
        ],
        jiezhi_time: [
          { required: true, validator: check_jiezhi_time, trigger: 'change' }
        ],
        peoples: [
          { type: 'array', required: true, message: '请至少选择一名应完成人员', trigger: 'change' }
        ]
      },
      rules_edit: {
        title: [
          { required: true, message: '请输入登记表标题', trigger: 'blur' }
        ],
        allow_format: [
          { type: 'array', required: true, message: '请至少一种文件类型', trigger: 'change' }
        ],
        limit_time: [
          { required: true, validator: check_limit_edit, trigger: 'change' }
        ],
        jiezhi_time: [
          { required: true, validator: check_jiezhi_time_edit, trigger: 'change' }
        ],
        peoples: [
          { type: 'array', required: true, message: '请至少选择一名应完成人员', trigger: 'change' }
        ]
      },

      currentPage: 1,  //默认初始页
      pagesize: 10,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

    };
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    HTMLPreview() {
      this.HTMLPreviewer = true
    },
    useTemplate() {
      if(this.dialogVisible === true) this.form.sj_notice = '<span style="color:red">请于2023年X月X日 XX:XX:XX前提交<br>注意事项：XXXXXX</span>';
      else if(this.show_edit_dialog === true) this.edit_digital_array.sj_notice = '<span style="color:red">请于2023年X月X日 XX:XX:XX前提交<br>注意事项：XXXXXX</span>'
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs['form'].resetFields();
          this.checkAll = false;
          this.dialogVisible = false;
          done();
        })
        .catch(_ => { });
    },
    handleClose_edit(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.edit_digital_array = {
            title: '',
            ifrename: false,
            rename: '',
            allow_format: [],
            sj_notice: '',
            ifouttime: true,
            iflimit: false,
            limit_time: [],
            jiezhi_time: '',
            peoples: [],
            if_folder: false,
            folder_name: '',
            ifrank: true,
            ifshenhe: false,
            isneed: true
          };
          this.show_edit_dialog = false;
          done();
        })
        .catch(_ => { });
    },
    handleShow(row) {
      this.show_digital_array = row
      this.show_digital_dialog = true
    },
    handleEdit(row) {
      this.show_edit_dialog = true
      this.edit_loading = true

      axios.get('/api/getCollectList.php', { params: { mode: 'edit', id: row.id } })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

          if (res.data.code != 200) {
            this.$message.error(res.data.msg)
          }
          else {
            let Base64 = {
              encode(str) {
                return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
                  function toSolidBytes(match, p1) {
                    return String.fromCharCode('0x' + p1);
                  }));
              },
              decode(str) {
                return decodeURIComponent(atob(str).split('').map(function (c) {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
              }
            };
            this.edit_digital_array = JSON.parse(JSON.stringify(row))
            this.$set(this.edit_digital_array, 'sj_notice', Base64.decode(res.data.sj_notice))
            this.$set(this.edit_digital_array, 'allow_format', res.data.allow_format)
            this.$set(this.edit_digital_array, 'rename', res.data.rename)
            this.$set(this.edit_digital_array, 'ifrename', res.data.ifrename)
            this.$set(this.edit_digital_array, 'ifouttime', res.data.ifouttime)
            this.$set(this.edit_digital_array, 'iflimit', res.data.iflimit)
            this.$set(this.edit_digital_array, 'limit_time', res.data.limit_time)
            this.$set(this.edit_digital_array, 'if_folder', res.data.if_folder)
            this.$set(this.edit_digital_array, 'folder_name', res.data.folder_name)
            this.$set(this.edit_digital_array, 'ifrank', res.data.ifrank)
            this.$set(this.edit_digital_array, 'ifshenhe', res.data.ifshenhe)
            this.$set(this.edit_digital_array, 'isneed', res.data.isneed)

            this.edit_loading = false
          }
        }).catch(err => {
          this.$message.error(err)
        })
    },
    handleShare(row) {
      this.show_digital_array = row
      this.share_link_dialog = true
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.peoples.sort((a, b) => parseInt(a) - parseInt(b));
          let peopleArraytoString = this.form.peoples.join(",");
          let FormatArraytoString = this.form.allow_format.join(",");
          let filteredNotice = this.form.sj_notice.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')

          let data = new FormData();

          data.append('title', this.form.title);
          data.append('ifrename', this.form.ifrename);
          data.append('time_frame', this.form.jiezhi_time);
          data.append('ifouttime', this.form.ifouttime)
          data.append('iflimit', this.form.iflimit)
          data.append('start_time', this.form.limit_time[0])
          data.append('end_time', this.form.limit_time[1])
          data.append('people', peopleArraytoString);
          data.append('file_name', this.form.rename);
          data.append('accept_format', FormatArraytoString);
          data.append('sj_notice', filteredNotice);
          data.append('if_folder', this.form.if_folder);
          data.append('folder_name', this.form.folder_name);
          data.append('ifrank', this.form.ifrank);
          data.append('ifshenhe', this.form.ifshenhe);
          data.append('isneed', this.form.isneed);


          this.axios({
            method: "post",
            url: '/api/CollectController.php?method=create',
            data: data,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
            .then(res => {
              if (res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

              if (res.data.code == 200) {
                this.tableData.unshift(res.data.new_data);
                this.$message.success('添加成功！');
                this.checkAll = false;
                this.$refs[formName].resetFields();
                this.dialogVisible = false;
              }
            }).catch(err => {
              console.log(err)
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onSubmitToEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let FormatArraytoString = this.edit_digital_array.allow_format.join(",");
          let filteredNotice = this.edit_digital_array.sj_notice.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '')

          let data = new FormData();

          data.append('id', this.edit_digital_array.id);
          data.append('title', this.edit_digital_array.title);
          data.append('time_frame', this.edit_digital_array.jiezhi_time);
          data.append('ifouttime', this.edit_digital_array.ifouttime);
          data.append('iflimit', this.edit_digital_array.iflimit);
          data.append('start_time', this.edit_digital_array.limit_time[0]);
          data.append('end_time', this.edit_digital_array.limit_time[1]);
          data.append('accept_format', FormatArraytoString);
          data.append('sj_notice', filteredNotice);
          data.append('ifrank', this.edit_digital_array.ifrank);
          data.append('ifshenhe', this.edit_digital_array.ifshenhe);
          data.append('isneed', this.edit_digital_array.isneed);


          this.axios({
            method: "post",
            url: '/api/CollectController.php?method=edit',
            data: data,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
            .then(res => {
              if (res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

              if (res.data.code == 200) {
                for (var i = 0; i < this.tableData.length; i++) {
                  if (this.tableData[i].id == this.edit_digital_array.id) {
                    this.tableData[i] = JSON.parse(JSON.stringify(this.edit_digital_array))
                    break;
                  }
                }
                this.$message.success('修改成功！');
                this.$refs[formName].resetFields();
                this.show_edit_dialog = false;
              }
            }).catch(err => {
              console.log(err)
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleDel(formdata) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.get('/api/CollectController.php', { params: { method: 'del', id: formdata.id } })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            }
            else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: '删除记录：“' + formdata.title + '”成功!'
              });
              for (var i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].id == formdata.id) {
                  this.tableData.splice(i, 1);
                }
              }
            } else {
              this.$message.error('失败：' + res.data.msg)
            }
          }).catch(err => {
            console.log(err)
          })
      })
    },
    handleCheckAll(event) {
      if (event.target.checked) {
        this.form.peoples = this.people_list.map(item => item.id);
      } else {
        this.form.peoples = []
      }
    },
    handleCheckedColumn(value) {
      if (value.length === this.people_list.length) {
        this.checkAll = true;
      } else {
        this.checkAll = false;
      }
    },
    refreshCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    toDataPanel(id) {
      this.$router.push('/user/showcollect/' + id)
    },
    getCollectList() {
      axios.get('/api/getCollectList.php')
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);

          this.people_list = res.data.people_list
          this.tableData = res.data.result

          this.pageCount = res.data.result.length
          this.loading = false

        }).catch(err => {
          console.log(err)
        })
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.create_width = "100%"
      } else {
        this.create_width = "60%"
      }
    }
  },
  computed: {
    filteredHtml() {
      if(this.dialogVisible === true) return this.form.sj_notice.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
      else if(this.show_edit_dialog === true) return this.edit_digital_array.sj_notice.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
    }
  },
  mounted() {
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)

    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true
    this.getCollectList()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
}
</script>