<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>报销审核</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-table :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
        <el-table-column prop="id" label="审核流水ID" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="title" :show-overflow-tooltip="true" label="审核内容" min-width="200px"></el-table-column>
        <el-table-column prop="money" label="金额" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="method" label="支出/收入" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.method == 1 ? 'danger' : 'success'" size="medium">{{scope.row.method == "1" ? "支出" : "收入"}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'primary' : (scope.row.status == 1 ? 'success' : 'danger')" size="medium">{{scope.row.status == "0" ? "待审核" : (scope.row.status == 1 ? "通过" : "驳回")}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="poster" label="申请人" min-width="100px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="sq_time" label="申请时间" min-width="160px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="control" label="操作" min-width="100px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button :type="scope.row.status == 0 ? 'primary' : 'success'" size="mini" @click="scope.row.status == 0 ? handleShow_Shenhe(scope.row,scope.row.id) : handleShow(scope.row,scope.row.id)">{{scope.row.status == 0 ? '前往审核' : '查看详情'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :pager-count="7"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length > 0 ? tableData.length : null"
        background
        style="float: right; margin-top: 20px"
      >
      </el-pagination>
      <el-dialog title="审核" :visible.sync="shenhe_digital_dialog" :width="create_width" :before-close="handleClose">
        <div v-loading="shenheloading">
          报销名称：<span>{{shenhe_digital_array.title}}</span><br>
          用途：<span style="color:red;">{{shenhe_digital_array.yt}}</span><br>
          报销金额：<span>{{shenhe_digital_array.money}}</span>元<br>
          消费时间：<span>{{shenhe_digital_array.xiaofei_time}}</span><br>
          报销凭证：<span><div style="text-align:center;margin 0 auto;width:450px;justify-content: center;align-items: center;"><img style="width:100%;" :src="shenhe_digital_array.img1"></div></span><br>
          收款二维码：<span><div style="text-align:center;margin 0 auto;width:450px;justify-content: center;align-items: center;"><img style="width:100%;" :src="shenhe_digital_array.img2"></div></span><br>
          申请人：<span>{{shenhe_digital_array.poster}}</span><br>
          申请时间：<span>{{shenhe_digital_array.sq_time}}</span><br>
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="批复内容" prop="ps">
              <el-input type="textarea" v-model="form.ps"></el-input>
            </el-form-item>
            <el-form-item label="审核结果" prop="status">
              <el-select v-model="form.status" placeholder="审核结果">
                <el-option label="通过" value="1"></el-option>
                <el-option label="驳回" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleSubmit('form', shenhe_digital_array.id)">确认批复</el-button>
          <el-button @click="handleClose">关闭</el-button>
        </span>
      </el-dialog>
      <el-dialog v-loading="showloading" title="详情" :visible.sync="show_digital_dialog" :width="create_width">
        <div v-loading="showloading">
          报销名称：<span>{{show_digital_array.title}}</span><br>
          用途：<span style="color:red;">{{show_digital_array.yt}}</span><br>
          报销金额：<span>{{show_digital_array.money}}</span>元<br>
          消费时间：<span>{{show_digital_array.xiaofei_time}}</span><br>
          申请状态：<span><el-tag :type="show_digital_array.status == 0 ? 'primary' : show_digital_array.status == 1 ? 'success' : 'danger'" size="mini">{{show_digital_array.status == 0 ? '待审核' : show_digital_array.status == 1 ? "通过" : "驳回"}}</el-tag></span><br>
          申请批复：<span>{{show_digital_array.ps}}</span><br>
          报销凭证：<span><div style="text-align:center;margin 0 auto;width:450px;justify-content: center;align-items: center;"><img style="width:100%;" :src="show_digital_array.img1"></div></span><br>
          收款二维码：<span><div style="text-align:center;margin 0 auto;width:450px;justify-content: center;align-items: center;"><img style="width:100%;" :src="show_digital_array.img2"></div></span><br>
          申请人：<span>{{show_digital_array.poster}}</span><br>
          申请时间：<span>{{show_digital_array.time}}</span><br>
          处理人：<span>{{show_digital_array.dealer}}</span><br>
          处理时间：<span>{{show_digital_array.deal_time}}</span><br>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="show_digital_dialog = false">关闭</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>

<script>
  import axios from 'axios'
  export default {
      name:'Shenhe',
      data() {
          return {
            show_digital_array: [],
            show_digital_dialog: false,
            shenhe_digital_array: [],
            shenhe_digital_dialog: false,
            showloading: false,
            shenheloading: false,
            tableData: [],

            create_width: '50%',

            form: {
              ps:'',
              status:''
            },
            rules: {
              status: [{ required: true, message: '请选择审核结果', trigger: 'change' }]
            },
            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        handleShow(row,id) {
          this.showloading = true

          axios.get('/api/getShenheList.php',{params:{mode:'detail',id:id}})
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
              else{
                this.show_digital_array = row
                this.show_digital_array.img1 = 'https://class.ljcljc.cn/public/file/' + res.data.img1
                this.show_digital_array.img2 = 'https://class.ljcljc.cn/public/file/' + res.data.img2
                this.show_digital_array.yt = res.data.yt
                this.show_digital_array.ps = res.data.ps
                this.show_digital_array.dealer = res.data.dealer
                this.show_digital_array.deal_time = res.data.deal_time
                this.show_digital_array.xiaofei_time = res.data.xiaofei_time
                this.showloading = false
              }
            }).catch(err => {
              console.log(err)
            })

          this.show_digital_dialog = true
        },
        handleShow_Shenhe(row,id) {
          this.shenheloading = true

          axios.get('/api/getShenheList.php',{params:{mode:'detail',id:id}})
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
              else{
                this.shenhe_digital_array = JSON.parse(JSON.stringify(row));
                this.shenhe_digital_array.img1 = 'https://class.ljcljc.cn/public/file/' + res.data.img1
                this.shenhe_digital_array.img2 = 'https://class.ljcljc.cn/public/file/' + res.data.img2
                this.shenhe_digital_array.yt = res.data.yt
                this.shenhe_digital_array.xiaofei_time = res.data.xiaofei_time
                this.shenheloading = false
              }
            }).catch(err => {
              console.log(err)
            })

          this.shenhe_digital_dialog = true
        },
        handleSubmit(formName, id) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.axios({
                method:"post",
                url: '/api/getShenheList.php?mode=deal&id=' + id,
                data: this.form,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                  if(res.data.code == 200) {
                    this.$message.success('审核成功');

                    this.tableData.forEach(item => {
                      if (item.id == id) {
                        this.$set(item, 'status', this.form.status)
                      }
                    })
                    
                    this.$refs[formName].resetFields();
                    this.shenhe_digital_dialog = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.$refs['form'].resetFields();
              this.shenhe_digital_dialog = false;
              done();
            })
            .catch(_ => {});
        },
        getShenheList() {
          axios.get('/api/getShenheList.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);

              this.tableData = res.data
              this.pageCount = res.data.length
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        },
        handleResize() {
          if (window.innerWidth < 992) {
            this.create_width = "100%"
          } else {
            this.create_width = "50%"
          }
        }
      },
      created() {
        this.getShenheList();
      },
      mounted() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize);
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
      }
  };
</script>
