<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>职业生涯规划</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <div>
        <div style="display: flex; align-items: center; margin-bottom: 10px;">
          <el-button type="primary" @click="createNewReport" style="margin-right: 5px;">生成新报告</el-button>
        </div>
        <el-table :data="careerReport.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%;">
          <el-table-column prop="unid" label="序号" width="80"></el-table-column>
          <el-table-column prop="title" label="报告名称" min-width="180" show-overflow-tooltip></el-table-column>
          <el-table-column prop="time" label="生成时间" min-width="180"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="medium" @click="redirectToReport(scope.row.id)" plain>查看报告</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :pager-count="7"
            :current-page="currentPage"
            :page-sizes="[10,20,30,50]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="careerReport.length > 0 ? careerReport.length : null"
            background
            style="float: right; margin-top: 20px"
        >
        </el-pagination>
      </div>
    </el-main>
  </el-main>
</template>

<script>
import axios from 'axios'

export default {
  name: 'VoteData',
  data() {
    return {
      loading: true,
      careerReport: [],
      currentPage: 1,
      pagesize: 10,
      pageCount: 0
    }
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    createNewReport(){
      this.$message.error('功能测试中，敬请期待！')
    },
    getCareerData() {
      axios.get('/api/getCareerReport.php')
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null) this.$message.error(res.data.msg);

            this.careerReport = res.data.reportlist
            this.loading = false

          }).catch(err => {
        console.log(err)
      })
    },
    redirectToReport(id) {
      this.$router.push('/user/CareerReport/' + id)
    }
  },
  mounted() {
    this.getCareerData();
  },
  created() {

  },
  computed: {
    ListArr() {
      return this.tableData.filter(function (item) {
        return item.finish_time === ''
      })
    },
    sortedArray() {
      return this.tableData.sort((a, b) => a.id - b.id)
    }
  }
};
</script>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>