<template>
    <div>
        <div v-if="check === true">正在跳转中...如果没有成功跳转，请<el-link type="primary" href="https://class.ljcljc.cn/user/UserCenter">点击此处</el-link></div>
        <div v-if="check === false">参数丢失，请尝试<el-link type="primary" href="https://class.ljcljc.cn/login">重新登陆</el-link></div>
    </div>
</template>


<script>
export default({
    name: 'QQLogin',
    data() {
        return {
            check: true
        }
    },
    methods: {
        redirectToPage() {
            if(this.$route.query.name == "" || this.$route.query.name == null) {
                this.$message.error('参数丢失，请尝试重新登录')
                this.check = false
            }
            else {
                this.$message.success('登录成功')
                localStorage.setItem('isLogin', 'true');
                localStorage.setItem('name', this.$route.query.name);
                localStorage.setItem('activeTab', '/user/UserCenter');
                if(this.$route.query.redirect != null && this.$route.query.redirect != '/') this.$router.push({ path: this.$route.query.redirect });
                else this.$router.push({ path: '/user/UserCenter' });
            }
            
        }
    },
    mounted() {
        this.redirectToPage()
    }
})
</script>
