<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>素拓管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <div>
        <div style="display: flex; align-items: center; margin-bottom: 10px;">
          <el-button type="primary" @click="getData" style="margin-right: 5px;">更新数据</el-button>
        </div>
        <el-form label-position="left" label-width="100px">
          <el-form-item label="年度：">
            <el-select v-model="recordYear" placeholder="请选择">
              <el-option
                  v-for="item in shenbaoYearOptions"
                  :key="item.id"
                  :label="item.competitionYear"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-row :gutter="20" style="padding: 0 0 10px 0">
          <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-card v-loading="loading">
              <div slot="header">
                <span>素拓分数据一览表</span>
              </div>
              <div style="height:350px;">
                <el-tabs v-model="activeName" :loading="loading">
                  <el-tab-pane label="申报分数排行榜" name="first" style="height:330px;">
                    <el-scrollbar style="height:95%">
                      <el-table style="width: 100%" :data="shenbaoScoreData"
                                :default-sort="{ prop: 'totalScore', order: 'descending' }">
                        <el-table-column type="index" label="排名" min-width="60px" header-align="center"
                                         align="center"></el-table-column>
                        <el-table-column prop="name" label="姓名" min-width="100px" header-align="center"
                                         align="center"></el-table-column>
                        <el-table-column prop="totalScore" label="申报总分" min-width="160px" header-align="center"
                                         align="center">
                        </el-table-column>
                      </el-table>
                    </el-scrollbar>
                  </el-tab-pane>
                  <el-tab-pane label="通过分数排行榜" name="second" style="height:330px;">
                    <el-scrollbar style="height:95%">
                      <el-table style="width: 100%" :data="shenbaoScoreData"
                                :default-sort="{ prop: 'totalSuccessScore', order: 'descending' }">
                        <el-table-column type="index" label="排名" min-width="60px" header-align="center"
                                         align="center"></el-table-column>
                        <el-table-column prop="name" label="姓名" min-width="100px" header-align="center"
                                         align="center"></el-table-column>
                        <el-table-column prop="totalSuccessScore" label="通过总分" min-width="160px"
                                         header-align="center"
                                         align="center">
                        </el-table-column>
                      </el-table>
                    </el-scrollbar>
                  </el-tab-pane>

                  <el-tab-pane label="导出" name="third">
                    <!--                    <el-button type="success" @click="exportToExcel" :disabled="disabled" plain>导出数据</el-button>-->
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-card>
          </el-col>
          <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-card v-loading="loading">
              <div slot="header">
                <span>审核情况图</span>
              </div>
              <div ref="finish_rate" style="width: 100%; height: 350px"></div>
            </el-card>
          </el-col>
        </el-row>
        <el-table :loading="loading" :data="compRecords.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
                  style="width: 100%;">
          <el-table-column prop="unid" label="序号" width="80"></el-table-column>
          <el-table-column prop="title" label="名称" min-width="180" show-overflow-tooltip></el-table-column>
          <el-table-column prop="declarerName" label="申报人" min-width="80" header-align="center" align="center"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="BeneficiaryName" label="受加分人" min-width="80" header-align="center" align="center"
                           show-overflow-tooltip></el-table-column>
          <el-table-column prop="leixing" label="类型" min-width="100" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="cur_class" label="等级" min-width="100" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="cur_rank" label="获奖情况" min-width="100" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="score" label="申报素拓分" min-width="80" header-align="center"
                           align="center"></el-table-column>
          <el-table-column prop="status" label="审核状态" min-width="80" header-align="center" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === '1'" type="warning" size="medium">待审核</el-tag>
              <el-tag v-if="scope.row.status === '2'" type="success" size="medium">审核通过</el-tag>
              <el-tag v-if="scope.row.status === '3'" type="danger" size="medium">审核驳回</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="180">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="showDetail(scope.row)" plain>查看详情</el-button>
              <el-button type="danger" size="mini" @click="rollback(scope.row)" plain v-if="scope.row.type !== '4'">
                撤回
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :pager-count="7"
            :current-page="currentPage"
            :page-sizes="[15,20,30,50]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="compRecords.length > 0 ? compRecords.length : null"
            background
            style="float: right; margin-top: 20px"
        >
        </el-pagination>
        <el-dialog title="详情" :visible.sync="show_digital_dialog" :width="create_width">
          <el-steps :active="shenhe_step" finish-status="success">
            <el-step title="申报" :description="show_digital_array.declarerName + '（已完成）'"></el-step>
            <el-step title="审核" :description="shenheDesc"></el-step>
            <el-step title="审核结果" :description="shenheResult"></el-step>
          </el-steps>
          <br>
          <el-descriptions title="详细信息" :column="show_digital_array.type === '1' ? 1 : 2" border
                           style="font-size: 14px">
            <el-descriptions-item label="名称">{{ show_digital_array.title }}</el-descriptions-item>
            <el-descriptions-item label="类型">{{ show_digital_array.leixing }}</el-descriptions-item>
            <el-descriptions-item label="比赛性质" v-if="show_digital_array.type === '1'">
              {{ show_digital_array.compXingzhi }}
            </el-descriptions-item>
            <el-descriptions-item label="主办方" v-if="show_digital_array.type === '1'">{{
                show_digital_array.compOrg
              }}
            </el-descriptions-item>
            <el-descriptions-item label="比赛级别" v-if="show_digital_array.type === '1'">
              {{ show_digital_array.compLevel }}
            </el-descriptions-item>
            <el-descriptions-item label="获奖等级" v-if="show_digital_array.type !== '5'">
              {{ show_digital_array.compClass }}
            </el-descriptions-item>
            <el-descriptions-item label="获奖情况"
                                  v-if="show_digital_array.type !== '3' && show_digital_array.type !== '5'">
              {{ show_digital_array.compRank }}
            </el-descriptions-item>
            <el-descriptions-item label="预计获得素拓分" v-if="show_digital_array.status === '1'">
              {{ show_digital_array.compScore }}
            </el-descriptions-item>
            <el-descriptions-item label="获得素拓分" v-if="show_digital_array.status === '2'">
              {{ show_digital_array.compScore }}
            </el-descriptions-item>
            <el-descriptions-item label="证明材料">
              <el-button type="primary" size="mini" @click="downloadFile(show_digital_array.compProofURL)">点击下载
              </el-button>
            </el-descriptions-item>
            <el-descriptions-item label="审核">
              <el-button type="warning" size="mini" @click="handleShenhe">点击审核
              </el-button>
            </el-descriptions-item>

          </el-descriptions>
          <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="show_digital_dialog = false">确 定</el-button>
                  </span>
        </el-dialog>
        <el-dialog title="审核" :visible.sync="dialogVisibleShenhe" :width="create_width">
          <div style="text-align: center;">请选择更新的审核状态</div>
          <br>
          <div style="text-align: center;">
            <el-button type="success" size="medium" @click="acceptShenhe" plain>审核通过</el-button>
            <el-button type="danger" size="medium" @click="rejectShenhe" plain>审核驳回</el-button>
          </div>
        </el-dialog>
      </div>
    </el-main>
  </el-main>
</template>

<style scoped>
.el-select {
  width: 100%;
}
</style>

<script>
import axios from 'axios'
import SIdentify from "@/components/SIdentify.vue";

export default {
  name: 'Sutuomgr',
  components: {SIdentify},
  data() {
    return {
      loading: false,
      compRecords: [],
      shenbaoOptions: [],
      shenbaoYearOptions: [],
      compList: [],
      compOrg: '',
      compLevel: '',
      compLevelList: [],
      compClassList: [],
      compRankList: [],
      compPersonList: [],
      compOtherList: [],
      compKaoheLevelList: [],
      compKaoheClassList: [],
      show_digital_array: {},

      activeName: 'first',

      recordYear: '1',
      totalScore: 0,
      totalScoreSuccess: 0,
      monitor: '',
      shenhe_step: 1,

      shenbaoScoreData: [],

      form: {
        shenbaoYearValue: '1',
        shenbaoValue: '',
        compChose: '',
        compXingzhi: '',
        compPersonChose: '',
        compOtherChose: '',
        comptWentiTitle: '',
        compClass: '',
        compRank: '',
        compKaohe_level: '',
        compKaohe_class: '',
        compScore: '',
        fileList_1: []
      },

      chart_data: [{value: 0, name: '待审核'}, {value: 0, name: '已审核'}],
      option: null,
      myChart: null,

      shenheDesc: '',
      shenheResult: '通过/驳回',

      dialogVisible: false,
      show_digital_dialog: false,
      dialogVisibleShenhe: false,
      create_width: '50%',


      currentPage: 1,
      pagesize: 50,
      pageCount: 0
    }
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.$refs['form'].resetFields();
            this.checkAll = false;
            this.isALL = false;
            this.dialogVisible = false;
            done();
          })
          .catch(_ => {
          });
    },
    handleShenhe() {
      this.dialogVisibleShenhe = true;
    },
    acceptShenhe() {
      axios.get('/api/CompController.php', {
        params: {
          method: 'handleShenhe',
          mode: 'accept',
          id: this.show_digital_array.id
        }
      })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

            if (res.data.code == 200) {
              for (let i = 0; i < this.compRecords.length; i++) {
                if (this.compRecords[i].id == this.show_digital_array.id) {
                  this.show_digital_array.status = '2';
                  this.shenheResult = '通过';
                  this.shenhe_step = 3;
                  this.compRecords[i].status = '2';
                  break;
                }
              }


              this.$message({
                type: 'success',
                message: '成功更新为审核通过！'
              });
            } else {
              this.$message({
                type: 'error',
                message: '操作失败！原因：' + res.data.msg
              });
            }
            this.dialogVisibleShenhe = false;

          }).catch(err => {
        console.log(err)
      })
    },
    rejectShenhe() {
      axios.get('/api/CompController.php', {
        params: {
          method: 'handleShenhe',
          mode: 'reject',
          id: this.show_digital_array.id
        }
      })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

            if (res.data.code == 200) {
              for (let i = 0; i < this.compRecords.length; i++) {
                if (this.compRecords[i].id == this.show_digital_array.id) {
                  this.show_digital_array.status = '3';
                  this.shenheResult = '驳回';
                  this.shenhe_step = 2;
                  this.compRecords[i].status = '3';
                  break;
                }
              }

              this.$message({
                type: 'success',
                message: '成功更新为审核驳回！'
              });
            } else {
              this.$message({
                type: 'error',
                message: '操作失败！原因：' + res.data.msg
              });
            }
            this.dialogVisibleShenhe = false;

          }).catch(err => {
        console.log(err)
      })
    },
    drawChart() {
      this.myChart = this.$echarts.init(this.$refs.finish_rate);
      this.option = {
        title: {
          text: '审核情况',
          left: 'center',
          top: 'center'
        },
        series: [
          {
            type: 'pie',
            data: this.chart_data,
            color: [
              '#fb7293',
              '#50C878'
            ],
            radius: ['40%', '70%']
          }
        ],
        tooltip: {
          show: true,
          trigger: 'item'
        }
      };
      this.myChart.setOption(this.option);
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })
    },
    loadScoreRecords() {
      axios.get('/api/CompController.php?method=getTotalScore&year=' + this.recordYear)
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null) this.$message.error(res.data.msg);

            this.shenbaoScoreData = res.data.data
          }).catch(err => {
        console.log(err)
      })
    },
    loadRecords() {
      this.currentPage = 1;
      this.pagesize = 50;
      this.pageCount = 0;
      this.loading = true
      this.compRecords = []
      this.totalScore = 0
      this.totalScoreSuccess = 0
      axios.get('/api/CompController.php?method=getAllRecords&year=' + this.recordYear)
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null) this.$message.error(res.data.msg);

            const records = res.data.data.records;
            let status_waiting = 0;
            let status_finish = 0;

            for (let i = 0; i < records.length; i++) {
              let cmptLeixing = '';
              let cur_class = '';
              let cur_rank = '';

              for (let j = 0; j < this.shenbaoOptions.length; j++) {
                if (records[i].competitionType == this.shenbaoOptions[j].id) {
                  cmptLeixing = this.shenbaoOptions[j].competitionType;
                  break;
                }
              }

              for (let j = 0; j < this.compClassList.length; j++) {
                if (records[i].competitionClass == this.compClassList[j].id) {
                  cur_class = this.compClassList[j].competitionClass;
                  break;
                }
              }

              for (let j = 0; j < this.compRankList.length; j++) {
                if (records[i].competitionRank == this.compRankList[j].id) {
                  cur_rank = this.compRankList[j].competitionRank;
                  break;
                }
              }

              let cur_score = records[i].competitionScore
              let data = {
                id: records[i].id,
                score: cur_score,
                unid: i + 1,
                title: records[i].competitionName,
                cmptId: records[i].competitionId,
                leixing: cmptLeixing,
                type: records[i].competitionType,
                cur_class: cur_class,
                cur_rank: cur_rank,
                rank: records[i].competitionRank,
                status: records[i].competitionStatus,
                proofURL: records[i].competitionProofURL,
                competitionClass: records[i].competitionClass,
                declarerName: records[i].declarerName,
                competitionXingzhi: records[i].competitionXingzhi,
                BeneficiaryName: records[i].BeneficiaryName
              }

              if (data.status === '1') status_waiting++;
              else status_finish++;

              this.compRecords.push(data);
            }
            this.chart_data[0].value = status_waiting
            this.chart_data[1].value = status_finish

            this.myChart.setOption(this.option);
            this.pageCount = this.compRecords.length
            this.currentPage = 1;
          })

      this.loadScoreRecords();
      this.loading = false;
    },
    getData() {
      axios.get('/api/CompController.php?method=get')
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null) this.$message.error(res.data.msg);

            const data = res.data.data;
            this.compClassList = data.competition_classes;
            this.compRankList = data.competition_ranks;
            this.compList = data.competition_lists;
            this.compOtherList = data.competition_others;
            this.compPersonList = data.competition_personalhonors;
            this.compLevelList = data.competition_levels;
            this.shenbaoYearOptions = data.competition_years;
            this.shenbaoOptions = data.competition_types;
            this.compKaoheLevelList = data.competition_kaoheLevels;
            this.compKaoheClassList = data.competition_kaoheClasses;
            this.monitor = data.monitor;

            this.loadRecords();


          }).catch(err => {
        console.log(err)
      })
    },
    showDetail(row) {
      let cur_compOrg = '';
      let cur_compLevel = '';
      if (row.type === '1') {
        for (let i = 0; i < this.compList.length; i++) {
          if (this.compList[i].id === row.cmptId) {
            cur_compOrg = this.compList[i].cmptOrg;
            cur_compLevel = this.compList[i].cmptLevel;
            for (let j = 0; j < this.compLevelList.length; j++) {
              if (this.compLevelList[j].id === cur_compLevel) {
                cur_compLevel = this.compLevelList[j].cmptLevel;
                break;
              }
            }
          }
        }
      }
      let cur_compRank = '';
      for (let i = 0; i < this.compRankList.length; i++) {
        if (this.compRankList[i].id === row.rank) {
          cur_compRank = this.compRankList[i].competitionRank;
          break;
        }
      }
      let cur_compClass = '';
      for (let i = 0; i < this.compClassList.length; i++) {
        if (this.compClassList[i].id === row.competitionClass) {
          cur_compClass = this.compClassList[i].competitionClass;
          break;
        }
      }

      this.show_digital_array = {
        id: row.id,
        title: row.title,
        leixing: row.leixing,
        type: row.type,
        compXingzhi: row.competitionXingzhi,
        compOrg: cur_compOrg,
        compLevel: cur_compLevel,
        compRank: cur_compRank,
        compClass: cur_compClass,
        status: row.status,
        compScore: row.score,
        compProofURL: row.proofURL,
        declarerName: row.declarerName
      }
      if (this.show_digital_array.status === '1') {
        this.shenheDesc = this.monitor + "（等待审核）";
        this.shenheResult = "通过/驳回";
        this.shenhe_step = 1;
      }
      if (this.show_digital_array.status === '2') {
        this.shenheDesc = this.monitor + "（已完成）";
        this.shenheResult = "通过";
        this.shenhe_step = 3;
      }
      if (this.show_digital_array.status === '3') {
        this.shenheDesc = this.monitor + "（已完成）";
        this.shenheResult = "驳回";
        this.shenhe_step = 2;
      }

      this.show_digital_dialog = true;
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.create_width = "100%"
      } else {
        this.create_width = "50%"
      }
    },
    downloadFile(url) {
      this.$message.success('成功发起下载请求，请耐心等待文件下载完成');
      let a = document.createElement('a');
      a.href = '/public/sutuo' + url;
      a.target = '_blank';
      a.click();
    },
    rollback(row) {
      this.$confirm('是否确定撤回该申请？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.get('/api/CompController.php?method=adminrollback&id=' + row.id)
            .then(res => {
              if (res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              } else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

              if (res.data.code == 200) {
                this.$message.success('撤回成功！');
                this.loadRecords();
              }
            })
      })

    }
  },
  mounted() {
    this.drawChart()
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true;
    this.getData();

  },
  computed: {
    ListArr() {
      return this.tableData.filter(function (item) {
        return item.finish_time === ''
      })
    },
    sortedArray() {
      return this.tableData.sort((a, b) => a.id - b.id)
    }
  }
};
</script>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>
