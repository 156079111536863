<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>我的成绩</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
                style="width: 100%">
        <el-table-column prop="id" label="序号" min-width="80px" header-align="center"
                         align="center"></el-table-column>
        <el-table-column prop="ExamName" :show-overflow-tooltip="true" label="考试名称" min-width="200px"></el-table-column>
        <el-table-column prop="km" :show-overflow-tooltip="true" label="考试科目" min-width="280px"></el-table-column>
        <el-table-column prop="ExamLeixing" label="考试类型" min-width="80px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-tag type="primary" size="medium" plain>{{
                scope.row.ExamLeixing == '1' ? '周考' : scope.row.ExamLeixing == '2' ? '月考' : scope.row.ExamLeixing == '3' ?
                    '期中考' : scope.row.ExamLeixing == '4' ? '期末考' : '学年结业'
              }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="ExamDate" label="考试时间" min-width="100px" header-align="center"
                         align="center"></el-table-column>
        <el-table-column label="操作" width="240px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="$router.push('/user/myReport/' + scope.row.ExamID)" v-if="scope.row.isOpen === '1'">查看成绩单</el-button>
            <el-button size="mini" type="danger" v-if="scope.row.isOpen === '0'" :disabled="true">暂未开放查询</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
          :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="tableData.length > 0 ? tableData.length : null"
          background style="float: right; margin-top: 20px">
      </el-pagination>
    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box{
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}
.breadcrumb_content{
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}

</style>

<script>
import axios from 'axios'
import * as XLSX from 'xlsx'
import { Loading } from 'element-ui'
import router from "@/router";

export default {
  name:'Personal',
  data() {
    return {
      subject_list: [],
      tableData: [],
      loading:true,

      currentPage: 1,  //默认初始页
      pagesize: 10,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

    };
  },
  methods: {
    router() {
      return router
    },
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    getExamList() {
      axios.get('/api/ExamController.php', { params: { method: 'getList' } })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            }
            else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

            this.subject_list = res.data.subject_list
            this.tableData = res.data.exam_list

            for (let k = 0; k < this.tableData.length; k++) {
              this.tableData[k].id = k+1
              let Subject_String = ''

              if (this.tableData[k].Subjects != '' && this.tableData[k].Subjects != null) {
                let arr = this.tableData[k].Subjects.split(',')

                for (let i = 0; i < arr.length; i++) {
                  for (let j = 0; j < res.data.subject_list.length; j++) {
                    if (arr[i] == res.data.subject_list[j].id) {
                      Subject_String = Subject_String + res.data.subject_list[j].subject_name + '、'
                      break;
                    }
                  }
                }

                Subject_String = Subject_String.substring(0, Subject_String.length - 1);
              }
              this.tableData[k].km = Subject_String
            }

            this.loading = false

          }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
  },
  created(){
    this.loading = true
    this.getExamList()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
