<template>
    <el-main style="padding:0px">
      <div class="breadcrumb_box">
        <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
          <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/user/votemgr' }">学生个性画像</el-breadcrumb-item>
          <el-breadcrumb-item>就业推荐</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-main>
        <el-row :gutter="20" style="padding: 0 0 10px 0">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-card v-loading="loading">
              <div slot="header">
                <span>个人情况一览表</span>
              </div>
              <div style="height:260px;">
                <el-tabs v-model="activeName">
                  <el-tab-pane label="个人信息" name="first" style="height:240px;">
                    <el-scrollbar style="height:95%">
                      <li class="list-group-item">
                        <b>姓名：</b>李锦成
                      </li>
                      <li class="list-group-item">
                        <b>评估数据量：</b><b>16</b>项个人荣誉 <b>10</b>项个人履历 <b>69</b>项任务完成情况
                      </li>
                      <li class="list-group-item">
                        <b>评估时间：</b>2024年2月26日 16:58:29
                      </li>
                      <li class="list-group-item">
                        <b>评估状态：</b>
                        <el-tag type="success" size="mini" plain>已完成</el-tag>
                      </li>
                      <li class="list-group-item">
                        <b>操作：</b>
                        <el-button type="primary" size="mini" plain>重新评估</el-button>
                      </li>
                    </el-scrollbar>
                  </el-tab-pane>
                  <el-tab-pane label="个人画像数据" name="second" style="height:210px;">
                    <el-scrollbar style="height:95%">
                      <el-table style="width: 100%" :data="tableData"
                        :default-sort="{ prop: 'finish_rank', order: 'ascending' }">
                        <el-table-column prop="finish_rank" sortable label="排名" min-width="60px" header-align="center"
                          align="center"></el-table-column>
                        <el-table-column prop="name" label="姓名" min-width="100px" header-align="center"
                          align="center"></el-table-column>
                        <el-table-column prop="finish_time" label="完成时间" min-width="160px" header-align="center"
                          align="center">
                          <template slot-scope="scope">
                            <span v-if="scope.row.finish_time !== ''">{{ scope.row.finish_time }}</span>
                            <span v-if="scope.row.finish_time === ''" style="color: red;">未完成</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-scrollbar>
                  </el-tab-pane>
                  <el-tab-pane label="导出数据" name="forth">
                    <el-button type="success" @click="exportToExcel" :disabled="disabled" plain>导出完成数据</el-button>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-card>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-card v-loading="loading">
              <div slot="header">
                <span>招聘信息数据源</span>
              </div>
              <div ref="finish_rate" style="width: 100%; height: 260px"></div>
            </el-card>
          </el-col>
        </el-row>
        <el-card v-loading="loading">
          <div slot="header">
            <span>推荐就业结果</span>
          </div>
          <el-row :gutter="20" style="padding: 0 0 10px 0">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div ref="vote_result_bar" style="width: 100%; height: 350px">
                <el-scrollbar style="height:95%">
                  <el-table :data="vote_result_table_data" style="width: 100%">
                    <el-table-column prop="voter_name" :show-overflow-tooltip="true" label="项目" header-align="center"
                      align="center" min-width="90px"></el-table-column>
                    <el-table-column prop="weidu" :show-overflow-tooltip="true" label="来源" header-align="center"
                      align="center" min-width="90px"></el-table-column>
                    <el-table-column prop="xinzi" :show-overflow-tooltip="true" label="薪资" header-align="center"
                      align="center" min-width="90px"></el-table-column>
                    <el-table-column prop="voter_rate" header-align="center" align="center" label="推荐度" min-width="80px">
                      <template slot-scope="scope">
                        {{ scope.row.voter_rate + '%' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="voter_rate" label="" min-width="150px">
                      <template slot-scope="scope">
                        <el-progress :show-text="false" :percentage="scope.row.voter_rate"></el-progress>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="150px" header-align="center" align="center">
                      <template slot-scope="scope">
                        <el-button type="primary" size="mini" plain>与HR沟通</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-scrollbar>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div ref="vote_result_pie" style="width: 100%; height: 350px"></div>
            </el-col>
          </el-row>
        </el-card>
      </el-main>
    </el-main>
  </template>
    
  <style scoped>
  .breadcrumb_box {
    height: 40px;
    width: 100%;
    background-color: #f6f8f8;
    border-bottom: 1px solid #eee;
    padding: 15px;
    margin: 0;
    display: flex;
  }
  
  .breadcrumb_content {
    font-size: 15px;
    position: absolute;
    margin: auto;
    left: 40px;
  }
  </style>
  
  <script>
  import axios from 'axios'
  import all_finish_icon from '@/assets/img/all_finish.png'
  import * as XLSX from 'xlsx'
  import { Loading } from 'element-ui'
  export default {
    name: 'VoteData',
    data() {
      return {
        loading: true,
        activeName: 'first',
        keyWord: '',
        orderType: 0,
        vote_data: {
          id: '',
          title: '',
          people_num: '',
          people_num_finish: '',
          select_peoples: [],
          isanonymous: false,
          status: 1,
          start_time: '',
          end_time: ''
        },
        vote_result_table_data: [
            {voter_name: 'Java开发实习生', weidu: 'BOSS直聘', xinzi: '130-150/天',voter_rate: 63},
            {voter_name: 'Java开发实习生', weidu: 'BOSS直聘',  xinzi: '100-200/天',voter_rate: 73},
            {voter_name: '软件工程师（实习生）', weidu: '智联招聘',  xinzi: '100-150/天',voter_rate: 50},
            {voter_name: 'java开发实习生', weidu: '猎聘',  xinzi: '2-3K·13薪',voter_rate: 73},
            {voter_name: '实习-Java工程师', weidu: 'BOSS直聘',  xinzi: '1-3K',voter_rate: 50},
            {voter_name: '实习Java', weidu: '智联招聘',  xinzi: '3-6K',voter_rate: 33},
            {voter_name: '软件工程师（Java实习生）', weidu: '智联招聘',  xinzi: '2-3K',voter_rate: 73},
            {voter_name: 'Java开发实习生', weidu: 'BOSS直聘',  xinzi: '100-200/天',voter_rate: 73},
            {voter_name: 'Java开发实习生', weidu: 'BOSS直聘',  xinzi: '100-200/天',voter_rate: 73}

        ],
        tableData: [],
        option: null,
        myChart: null,
        myChartVoteChart: null,
        optionVote: null,
        chart_data: [{ value: 0, name: '已完成' }, { value: 0, name: '未完成' }],
        chart_vote_data: [{name: '学术表现', value: 63.00},
                        {name: '认知与智力特点', value: 59.00},
                        {name: '人格特质与性格特征', value: 68.33},
                        {name: '兴趣爱好与特长', value: 50.00},
                        {name: '社交能力与人际关系', value: 77.88},
                        {name: '心理健康与情绪状态', value: 80.32},
                        {name: '生涯规划与目标设定', value: 88.54}],
        all_finish_img: all_finish_icon,
  
        disabled: false,
  
        currentPage: 1,  //默认初始页
        pagesize: 10,    //每页默认显示的数据
        pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改
  
      };
    },
    methods: {
      handleSizeChange: function (size) {
        this.pagesize = size;
      },
      //切换页码
      handleCurrentChange: function (currentPage) {
        this.currentPage = currentPage;
      },
      handleShowData(data) {
        let resultArr = []
        for (let i = 0; i < data.peo_select_people.length; i++) {
          for (let j = 0; j < this.vote_data.select_peoples.length; j++) {
            if (this.vote_data.select_peoples[j].id == data.peo_select_people[i]) {
              resultArr.push(this.vote_data.select_peoples[j].name)
              break;
            }
          }
        }
        let resultStr = resultArr.join('，');
  
  
        this.$alert(data.name + '的投票结果为：<strong>' + resultStr + '</strong>', '投票结果', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true
        });
      },
  
      drawChart() {
        this.myChart = this.$echarts.init(this.$refs.finish_rate);
        this.option = {
          title: {
            text: '数据源',
            left: 'center',
            top: 'center'
          },
          series: [
            {
              type: 'pie',
              data: [{ value: 17, name: 'BOSS直聘' }, { value: 16, name: '智联招聘' }, { value: 20, name: '58同城' }, {value: 20, name: '猎聘'}, {value: 5, name: '其他'}],
              color: [
                '#50C878',
                '#fb7293',
                '#409EFF',
                '#FF7043',
                '#C678BF'
              ],
              radius: ['40%', '70%']
            }
          ],
          tooltip: {
            show: true,
            trigger: 'item'
          }
        };
        this.myChart.setOption(this.option);
        window.addEventListener('resize', () => {
          this.myChart.resize()
        })
  
      },
  
      drawVoteChart() {
        this.myChartVoteChart = this.$echarts.init(this.$refs.vote_result_pie);
        this.optionVote = {
          legend: {
            top: 'bottom'
          },
          toolbox: {
            show: true,
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: true },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          title: {
            text: '推荐方向',
            left: 'center',
            top: 'top'
          },
          series: [
            {
              name: '评估维度',
              type: 'pie',
              radius: [30, 100],
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 8
              },
              color: [
                '#5470c6',
                '#91cc75',
                '#fac858',
                '#ee6666',
                '#73c0de',
                '#3ba272',
                '#fc8452',
                '#9a60b4',
                '#ea7ccc'
              ],
              data: [{name: 'Java开发实习生', value: 63.00},
                    {name: 'Linux运维', value:20.00},
                    {name: '前端开发', value: 27.00}]
            }
          ],
          tooltip: {
            show: true,
            trigger: 'item'
          }
        };
        this.myChartVoteChart.setOption(this.optionVote);
        window.addEventListener('resize', () => {
          this.myChartVoteChart.resize()
        })
  
      },
      exportToExcel() {
        this.disabled = true
        let loading = Loading.service({
          text: '数据生成中，请稍后...',
          background: 'rgba(0,0,0,.5)'
        })
  
        let arr = this.sortedArray.map(item => {
          let wc;
          if (item.finish_time == "") wc = '未完成';
          else wc = '已完成';
  
          return {
            号数: item.id,
            学号: item.classid,
            姓名: item.name,
            完成状态: wc,
            完成时间: item.finish_time
          }
        })
  
  
        //const data = this.tableData // 表格数据
        const ws = XLSX.utils.json_to_sheet(arr)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, this.vote_data.title)
        XLSX.writeFile(wb, `投票-${this.vote_data.title}数据${new Date().getTime()}.xlsx`)
  
        loading.close()
        this.disabled = false
      },
      getVoteData() {
        axios.get('/api/VoteController.php', { params: { method: 'getDetail', id: this.$route.params.id } })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            }
            else if (res.data.msg != null) this.$message.error(res.data.msg);
  
            this.vote_data.id = res.data.id
            this.vote_data.title = res.data.title
            this.vote_data.people_num = res.data.people_num
            this.vote_data.start_time = res.data.start_time
            this.vote_data.end_time = res.data.end_time
            this.vote_data.status = res.data.status
            this.vote_data.isanonymous = res.data.isanonymous
            this.vote_data.select_peoples = res.data.select_peoples
            this.vote_data.vote_result = res.data.vote_result
            this.vote_data.select_num = res.data.select_num
  
            for (let i = 0; i < res.data.select_peoples.length; i++) {
              this.vote_result_table_data.push({
                id: res.data.select_peoples[i].id,
                voter_name: res.data.select_peoples[i].name,
                voter_num: 0,
                voter_rate: 0
              })
              this.chart_vote_data.push({
                id: res.data.select_peoples[i].id,
                value: 0,
                name: res.data.select_peoples[i].name
              })
            }
            this.vote_result_table_data.push({
              id: 99999,
              voter_name: '未选票',
              voter_num: 0,
              voter_rate: 0
            })
  
  
            for (let i = 0; i < res.data.vote_result.length; i++) {
              if (res.data.vote_result[i].voteId == '') {
                for (let j = 0; j < this.vote_result_table_data.length; j++) {
                  if (this.vote_result_table_data[j].id == 99999) {
                    this.vote_result_table_data[j].voter_num = res.data.vote_result[i].value * res.data.select_num;
                    this.vote_result_table_data[j].voter_rate = Math.trunc(((res.data.vote_result[i].value * res.data.select_num) / (res.data.people_num * res.data.select_num)).toFixed(4) * 10000) / 10000 * 100;
                    break;
                  }
                }
              }
  
              for (let j = 0; j < this.vote_result_table_data.length; j++) {
                if (this.vote_result_table_data[j].id == res.data.vote_result[i].voteId) {
                  this.vote_result_table_data[j].voter_num = res.data.vote_result[i].value;
                  this.vote_result_table_data[j].voter_rate = Math.trunc((res.data.vote_result[i].value / (res.data.people_num * res.data.select_num)).toFixed(4) * 10000) / 10000 * 100
                  break;
                }
              }
  
              for (let j = 0; j < this.chart_vote_data.length; j++) {
                if (this.chart_vote_data[j].id == res.data.vote_result[i].voteId) {
                  this.chart_vote_data[j].value = res.data.vote_result[i].value;
                  break;
                }
              }
  
            }
  
            this.myChartVoteChart.setOption(this.optionVote);
  
            this.vote_result_table_data.sort((a, b) => a.id - b.id);
  
  
            this.chart_data[0].value = res.data.people_num_finish
            this.chart_data[1].value = res.data.people_num - res.data.people_num_finish
  
            this.tableData = res.data.list
            this.pageCount = res.data.list.length
  
            this.myChart.setOption(this.option)
            this.loading = false
  
          }).catch(err => {
            console.log(err)
          })
      }
    },
    mounted() {
      this.drawChart();
      this.drawVoteChart();
    },
    created() {
      this.loading = false
    //   this.getVoteData()
    },
    computed: {
      ListArr() {
        return this.tableData.filter(function (item) {
          return item.finish_time === ''
        })
      },
      sortedArray() {
        return this.tableData.sort((a, b) => a.id - b.id)
      }
    }
  };
  </script>
    