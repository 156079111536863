<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>个人履历</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-button type="primary" @click="dialogVisible = true" plain>新增记录</el-button>
      <el-button type="success" @click="exportToExcel" :disabled="disabled" plain>导出数据</el-button>
      <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)" style="width: 100%">
        <el-table-column prop="id" label="序号" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="title" label="记录名称" min-width="200px"></el-table-column>
        <el-table-column prop="duration" label="时长" header-align="center" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.duration}}小时</span>
          </template>
        </el-table-column>
        <el-table-column prop="region" label="地点" header-align="center" align="center"></el-table-column>
        <el-table-column prop="leixing" label="记录类型" header-align="center" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.leixing == 1">志愿活动</span>
            <span v-else-if="scope.row.leixing == 2">社会实践</span>
            <span v-else-if="scope.row.leixing == 3">参与比赛</span>
            <span v-else-if="scope.row.leixing == 4">研究发明</span>
            <span v-else-if="scope.row.leixing == 5">软件著作</span>
            <span v-else-if="scope.row.leixing == 6">其他</span>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间" min-width="120px" header-align="center" align="center"></el-table-column>
        <el-table-column label="操作" width="240px">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="handleShow(scope.row)">查看详情</el-button>
            <el-button size="mini" type="info" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDel(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :pager-count="7"
        :current-page="currentPage"
        :page-sizes="[10,20,30,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length > 0 ? tableData.length : null"
        background
        style="float: right; margin-top: 20px"
      >
      </el-pagination>
      <el-dialog
        title="新增记录"
        :visible.sync="dialogVisible"
        :width="create_width"
        :before-close="handleClose">
          <el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px">
            <el-form-item label="记录名称" prop="title">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="时长" prop="duration">
              <el-input v-model="form.duration" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="单位：小时"></el-input>
            </el-form-item>
            <el-form-item label="地点" prop="region">
              <el-input v-model="form.region"></el-input>
            </el-form-item>
            <el-form-item label="记录类型" prop="leixing">
              <el-select v-model="form.leixing" placeholder="记录类型">
                <el-option label="志愿活动" value="1"></el-option>
                <el-option label="社会实践" value="2"></el-option>
                <el-option label="参与比赛" value="3"></el-option>
                <el-option label="研究发明" value="4"></el-option>
                <el-option label="软件著作" value="5"></el-option>
                <el-option label="其他" value="6"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间" prop="time">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" v-model="form.time" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('form')">确认增加</el-button>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog
        title="编辑"
        :visible.sync="show_edit_dialog"
        :width="create_width"
        :before-close="handleClose_edit">
          <el-form ref="edit_form" status-icon :model="edit_digital_array" :rules="rules" label-width="80px">
            <el-form-item label="记录名称" prop="title">
              <el-input v-model="edit_digital_array.title"></el-input>
            </el-form-item>
            <el-form-item label="时长" prop="duration">
              <el-input v-model="edit_digital_array.duration" oninput ="value=value.replace(/[^0-9.]/g,'')" placeholder="单位：小时"></el-input>
            </el-form-item>
            <el-form-item label="地点" prop="region">
              <el-input v-model="edit_digital_array.region"></el-input>
            </el-form-item>
            <el-form-item label="记录类型" prop="leixing">
              <el-select v-model="edit_digital_array.leixing" placeholder="记录类型">
                <el-option label="志愿活动" value="1"></el-option>
                <el-option label="社会实践" value="2"></el-option>
                <el-option label="参与比赛" value="3"></el-option>
                <el-option label="研究发明" value="4"></el-option>
                <el-option label="软件著作" value="5"></el-option>
                <el-option label="其他" value="6"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间" prop="time">
              <el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" v-model="edit_digital_array.time" style="width: 100%;"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmitToEdit('edit_form')">确认修改</el-button>
            </el-form-item>
          </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose_edit">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog title="记录详情" :visible.sync="show_digital_dialog" :width="create_width">
        <span>ID：{{show_digital_array.unid}}</span><br>
        <span>记录名称：{{show_digital_array.title}}</span><br>
        <span>时长：{{show_digital_array.duration}}小时</span><br>
        <span>地点：{{show_digital_array.region}}</span><br>
        <span>记录类型：
          <span v-if="show_digital_array.leixing == 1">志愿活动</span>
          <span v-else-if="show_digital_array.leixing == 2">社会实践</span>
          <span v-else-if="show_digital_array.leixing == 3">参与比赛</span>
          <span v-else-if="show_digital_array.leixing == 4">研究发明</span>
          <span v-else-if="show_digital_array.leixing == 5">软件著作</span>
          <span v-else-if="show_digital_array.leixing == 6">其他</span>
        </span><br>
        <span>时间：{{show_digital_array.time}}</span><br>
        <span>记录人学号：{{show_digital_array.classid}}</span><br>
        <span>记录人：{{show_digital_array.poster}}</span><br>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="show_digital_dialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }
    
</style>

<script>
  import axios from 'axios'
  import * as XLSX from 'xlsx'
  import { Loading } from 'element-ui'

  export default {
      name:'Document',
      data() {
          return {
            dialogVisible: false,
            show_digital_dialog: false,
            show_edit_dialog: false,
            show_digital_array: {},
            edit_digital_array: {},
            loading: true,
            tableData: [],
            disabled: false,

            create_width: '50%',

            pickerOptions: {
              disabledDate(time) {
                return time.getTime() > Date.now();
              },
            },

            form: {
              title: '',
              duration: '',
              region: '',
              leixing: '',
              time: ''
            },
            
            rules: {
              title: [
                { required: true, message: '请输入记录名称', trigger: 'blur' }
              ],
              duration: [
                { required: true, message: '请输入时长', trigger: 'blur' }
              ],
              region: [
                { required: true, message: '请输入地点', trigger: 'blur' }
              ],
              leixing: [
                { required: true, message: '请选择记录类型', trigger: 'change' }
              ],
              time: [
                { required: true, message: '请选择时间', trigger: 'change' }
              ]
            },
            
          
            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

          };
      },
      methods: {
        handleSizeChange: function (size) {
          this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
          this.currentPage = currentPage;
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.$refs['form'].resetFields();
              this.dialogVisible = false;
              done();
            })
            .catch(_ => {});
        },
        handleClose_edit(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.$refs['edit_form'].resetFields();
              this.show_edit_dialog = false;
              done();
            })
            .catch(_ => {});
        },
        handleShow(row) {
          this.show_digital_dialog = true
          this.show_digital_array = row
        },
        handleEdit(row){
          this.show_edit_dialog = true
          this.edit_digital_array = JSON.parse(JSON.stringify(row))
        },
        onSubmit(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.axios({
                method:"post",
                url: '/api/getDocumentList.php?method=setNew',
                data:this.form,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                  if(res.data.code == 200) {
                    let new_data = {
                      id: this.tableData.length + 1,
                      unid: res.data.new_data.unid,
                      title: res.data.new_data.title,
                      time: res.data.new_data.time,
                      duration: res.data.new_data.duration,
                      poster: res.data.new_data.poster,
                      leixing: res.data.new_data.leixing,
                      region: res.data.new_data.region,
                      classid: res.data.new_data.classid
                    }
                    this.tableData.push(new_data);
                    this.$message.success('添加成功！');
                    this.$refs[formName].resetFields();
                    this.dialogVisible = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        onSubmitToEdit(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.axios({
                method:"post",
                url: '/api/getDocumentList.php?method=edit',
                data:this.edit_digital_array,
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                }
              })
                .then(res => {
                  if(res.data.code != null && res.data.code == 400) {
                    this.$message.error('登录状态过期，请重新登录');
                    localStorage.clear('name');
                    localStorage.clear('isLogin');
                    this.$router.push('/login?refer=' + window.location.pathname)
                  }
                  else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);
                  
                  if(res.data.code == 200) {
                    for(var i=0;i<this.tableData.length;i++) {
                      if(this.tableData[i].id == this.edit_digital_array.id) {
                        this.tableData[i] = JSON.parse(JSON.stringify(this.edit_digital_array))
                        break;
                      }
                    }
                    this.$message.success('修改成功！');
                    this.$refs[formName].resetFields();
                    this.show_edit_dialog = false;
                  }
                }).catch(err => {
                  console.log(err)
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        handleDel(formdata) {
          this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.get('/api/getDocumentList.php',{params:{method:'del', id:formdata.unid}})
              .then(res => {
                if(res.data.code != null && res.data.code == 400) {
                  this.$message.error('登录状态过期，请重新登录');
                  localStorage.clear('name');
                  localStorage.clear('isLogin');
                  this.$router.push('/login?refer=' + window.location.pathname)
                }
                else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                if(res.data.code == 200) {
                  this.$message({
                    type: 'success',
                    message: '删除记录：“'+formdata.title+'”成功!'
                  });
                  for(var i=0;i<this.tableData.length;i++) {
                    if(this.tableData[i].id == formdata.id) {
                      this.tableData.splice(i, 1);
                      for(var j=i;j<this.tableData.length;j++) {
                        this.tableData[j].id--;
                      }
                      break;
                    }
                  }
                } else {
                  this.$message.error('失败：' + res.data.msg)
                }
              }).catch(err => {
                console.log(err)
              })
          })
        },
        exportToExcel() {
          this.disabled = true
          let loading = Loading.service({
            text: '数据生成中，请稍后...',
            background: 'rgba(0,0,0,.5)'
          })
           let arr = this.tableData.map(item => {
            let leixing;
              if(item.leixing == 1) leixing = '志愿活动';
              if(item.leixing == 2) leixing = '社会实践';
              if(item.leixing == 3) leixing = '参与比赛';
              if(item.leixing == 4) leixing = '研究发明';
              if(item.leixing == 5) leixing = '软件著作';
              if(item.leixing == 6) leixing = '其他';

              return {
                序号: item.id,
                记录名称: item.title,
                时长: item.duration,
                地点: item.region,
                记录类型: leixing,
                时间: item.time + '小时',
                学号: item.classid,
                记录人: item.poster
              }
            })
            

          //const data = this.tableData // 表格数据
          const ws = XLSX.utils.json_to_sheet(arr)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, '个人履历')
          XLSX.writeFile(wb, `个人履历${new Date().getTime()}.xlsx`)

          loading.close()
          this.disabled = false
        },
        getDocumentList() {
          axios.get('/api/getDocumentList.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);
              
              this.tableData = res.data
              this.pageCount = res.data.length
              this.loading = false
              console.log(res.data.length)
            }).catch(err => {
              console.log(err)
            })
        },
        handleResize() {
          if (window.innerWidth < 992) {
            this.create_width = "100%"
          } else {
            this.create_width = "50%"
          }
        }
      },
      mounted() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize);
      },
      created() {
        this.loading = true
        this.getDocumentList()
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
      }
    
  };
</script>
