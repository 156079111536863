<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>账号信息设置</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card>
            <div slot="header">
              <span>我的信息</span>
            </div>
            <div v-loading="loading">
              <li class="list-group-item">
							  <b>姓名：</b><span style="color: orange"><b>{{userData.name}}</b></span>
						  </li>
              <li class="list-group-item">
							  <b>职务：</b><span style="color: red"><b>{{userData.zhiwu}}</b></span>
						  </li>
              <li class="list-group-item">
							  <b>用户权限：</b>
                <span style="color: red" v-if="userData.qx == 1"><b>超级管理员</b></span>
                <span style="color: red" v-if="userData.qx == 2"><b>管理员</b></span>
                <span style="color: red" v-if="userData.qx == 3"><b>普通用户</b></span>
						  </li>
              <li class="list-group-item">
							  <b>QQ绑定状态：</b>
                <span style="color: green" v-if="userData.ifQQ == true"><b>已绑定</b></span>
                <span style="color: red" v-if="userData.ifQQ == false"><b>未绑定</b>&nbsp;&nbsp;<el-button type="primary" size="mini" @click="toBindAccount">绑定</el-button></span>
						  </li>
              <li class="list-group-item">
							  <b>微信小程序绑定状态：</b>
                <span style="color: green" v-if="userData.ifWX == true"><b>已绑定</b></span>
                <span style="color: red" v-if="userData.ifWX == false"><b>未绑定</b>&nbsp;&nbsp;<el-button type="primary" size="mini" @click="bindWXTips = true">绑定</el-button></span>
						  </li>
              <li style="font-weight:bold" class="list-group-item">程序版本：<span style="color: purple">v3.4.0</span>&nbsp;
              <el-link href="https://class.ljcljc.cn/about.html" target="_blank" :underline="false"><el-button type="warning" size="mini">关于程序</el-button></el-link>&nbsp;
              <el-link href="https://support.qq.com/product/348012" target="_blank" :underline="false"><el-button type="success" size="mini">反馈</el-button></el-link>
						</li>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card>
            <div slot="header">
              用户资料设置
            </div>
            <div v-loading="loading">
              <el-form ref="form" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="姓名" prop="name">
                  <el-input v-model="form.name" readonly></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="classid">
                  <el-input v-model="form.classid" readonly></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="tel">
                  <el-input v-model="form.tel" readonly></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="passwd">
                  <el-input ref="newPasswd" v-model="form.passwd" placeholder="请输入修改的密码（不修改请留空）"></el-input>

                  <div class="input_span">
                    <span :style="{'background-color':(msgText > 1 || msgText == 1) ?  '#FC5F76' : '#BBBBBB', 'color': (msgText > 1 || msgText == 1) ?  '#FC5F76' : '#BBBBBB' }">弱</span>
                    <span :style="{'background-color':(msgText > 2 || msgText == 2) ?  '#FF9900' : '#BBBBBB', 'color': (msgText > 2 || msgText == 2) ?  '#FF9900' : '#BBBBBB'}">中</span>
                    <span :style="{'background-color':(msgText > 4 || msgText == 4) ?  '#33CC00' : '#BBBBBB', 'color': (msgText > 4 || msgText == 4) ?  '#33CC00' : '#BBBBBB'}">强</span>
                  </div>
                  <span>新密码至少8个字符,必须包含字母、数字！</span>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit('form')">确认修改</el-button>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </el-col>
      </el-row>

      <el-dialog title="微信小程序绑定方式" :visible.sync="bindWXTips" :width="create_width">
        <div v-loading="show_loading" element-loading-text="拼命加载中">
          <div>
            <el-steps :active="active" finish-status="success">
              <el-step title="步骤 1"></el-step>
              <el-step title="步骤 2"></el-step>
              <el-step title="步骤 3"></el-step>
            </el-steps>
            <div style="text-align: center; margin-top: 30px;" v-if="active === 0">
              <h3>使用微信扫描下方小程序二维码</h3>
              <img src="../assets/img/miniprogram.jpg" height="250px" width="250px"/>
            </div>
            <div style="text-align: center; margin-top: 30px;" v-if="active === 1">
              <h3>在“首页”中，点击“点击登录”按钮，授权微信登录后，按提示完成账号绑定</h3>
              <img src="../assets/img/wxlogin.jpg" height="auto" width="350px"/>
            </div>
            <div style="text-align: center; margin-top: 30px;" v-if="active === 2">
              <h3>若已完成前两个步骤，那么</h3><br>
              <h2>恭喜你！你已经完成了微信小程序的绑定！</h2><br>
              <h4>刷新页面后即可更新绑定状态</h4>
            </div>
            <div style="text-align: center; margin-top: 30px;">
              <el-button type="primary" @click="next">{{ active === 2 ? '再来一次' : '下一步'}}</el-button>
            </div>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="bindWXTips = false">关闭</el-button>
        </span>
      </el-dialog>

    </el-main>
  </el-main>
</template>

<style scoped>
    .breadcrumb_box{
      height: 40px;
      width: 100%;
      background-color: #f6f8f8;
      border-bottom: 1px solid #eee;
      padding: 15px;
      margin: 0;
      display: flex;
    }
    .breadcrumb_content{
      font-size: 15px;
      position: absolute;
      margin: auto;
      left: 40px;
    }

    .input_span{
      height: 8px;
      display: flex;
      float: right;
      width: 35%;
    }
    .input_span span{
      display: inline-block;
      width: 30%;
      border-radius: 8px;
      margin-right: 3px;
      text-align: center;
      margin-top: 3px;
    }

</style>

<script>
  import axios from 'axios'
  export default {
      name:'UserSettings',
      data() {
          return {
            userData:{},
            loading: true,
            msgText: "",
            form:{
              name:'',
              classid:'',
              tel:'',
              passwd:''
            },

            active: 0,

            bindWXTips: false,
            create_width: '50%',

            rules: {
              passwd: [
                { required: true, message: '请输入新密码', trigger: 'blur' }
              ],

            },

          };
      },
      methods: {
        checkStrong(sValue) {
          var modes = 0;
          //正则表达式验证符合要求的
          if (sValue.length < 1) return modes;
          if (/\d/.test(sValue)) modes++;
          if (/[a-z]/.test(sValue)) modes++;
          if (/[A-Z]/.test(sValue)) modes++;
          if (/\W/.test(sValue)) modes++;
          switch (modes) {
            case 1:
              return 1;
              break;
            case 2:
              return 2;
              break;
            case 3:
            case 4:
            return sValue.length < 4 ? 3 : 4;
            break;
          }
          return modes;
        },
        toBindAccount() {
          window.open('https://class.ljcljc.cn/oauth/qq_login.php', '_self');
        },
        onSubmit(formName) {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              var pwd=this.form.passwd
				      var pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}')
				      if(!pwdRegex.test(pwd)) {
				        this.$message.error("密码强度不符合要求！");
				        this.$refs.newPasswd.focus();
				        return false;
				      }
              else {
                let data = new FormData();
                data.append('newpasswd',this.form.passwd);

                this.axios({
                  method:"post",
                  url: '/api/getUserData.php?method=change',
                  data: data,
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
                })
                  .then(res => {
                    if(res.data.code != null && res.data.code == 400) {
                      this.$message.error('登录状态过期，请重新登录');
                      localStorage.clear('name');
                      localStorage.clear('isLogin');
                      this.$router.push('/login?refer=' + window.location.pathname)
                    }
                    else if(res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

                    if(res.data.code == 200) {
                      this.$message.success('密码修改成功！');
                      this.$set(this.form, 'passwd', null);
                    }
                  }).catch(err => {
                    console.log(err)
                  })
              }
            } else {
              console.log('error submit!!');
              return false;
            }
          });
        },
        getUserData() {
          axios.get('/api/getUserData.php')
            .then(res => {
              if(res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if(res.data.msg != null) this.$message.error(res.data.msg);

              this.userData = res.data.userData
              this.form = res.data.userForm
              this.passwd = ''
              this.loading = false
            }).catch(err => {
              console.log(err)
            })
        },
        handleResize() {
          if (window.innerWidth < 992) {
            this.create_width = "100%"
          } else {
            this.create_width = "50%"
          }
        },
        next() {
          if (this.active++ > 1) this.active = 0;
        }
      },
      mounted() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize);
      },
      watch: {
        'form.passwd': {
          handler(keyWord, oldname) {
            this.msgText = this.checkStrong(keyWord);
          }
        }
      },
      created() {
        this.loading = true;
        this.getUserData();
        if (this.form.passwd){
          this.msgText = this.checkStrong(this.form.passwd);
        }
      },
      beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
      }
  };
</script>
