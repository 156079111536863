<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/myScore' }">我的成绩</el-breadcrumb-item>
        <el-breadcrumb-item>成绩单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>

      <el-card v-loading="loading">
        <div slot="header">
          <span>{{ examName }}-成绩单</span>
        </div>
        <li class="list-group-item" v-for="(item, index) in subject_info" :key="index">
          <b>{{ item.SubjectCNName }}：</b>{{ item.SubjectScore }}分
        </li>
        <li class="list-group-item">
          <b>综合学分绩点：{{ zonghe }}</b>
        </li>
        <li class="list-group-item">
          <b>专业排名：{{ zhuanye_rank }}</b>
        </li>
        <li class="list-group-item">
          <b>班级排名：{{ class_rank }}</b>
        </li>
      </el-card>
    </el-main>

  </el-main>
</template>

<style scoped>
.list-group-item {
  position: relative;
  display: flex;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.list-group-item:first-child {
  margin-top: 5px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.breadcrumb_box{
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}
.breadcrumb_content{
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>

<script>
export default {
  name: 'examreport',
  data() {
    return {
      subject_info: [],
      examName: '',
      class_rank: 'N/A',
      zhuanye: 'N/A',
      zonghe: 'N/A',
      loading: false
    }
  },
  methods: {
    getExamList() {
      axios.get('/api/ExamController.php', {params: {method: 'getReport', id: this.$route.params.id}})
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            } else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

            if(res.data.isOpen === '0') {
              this.$message.error('无数据或暂未开放查询')
              return ;
            }

            this.examName = res.data.examName;
            this.subject_info = res.data.subject_output;
            this.class_rank = res.data.class_rank;
            this.zhuanye_rank = res.data.zhuanye_rank;
            this.zonghe = res.data.zonghe;


            this.loading = false

          }).catch(err => {
        console.log(err)
      })
    },

  },
  mounted() {
  },
  created() {
    this.loading = true;
    this.getExamList();
  }

}
</script>
