<template>
  <div class="login">
    <div class="container" :style="boxExteraStyle">
      <div class="login-form" :style="exteraStyle">
        <h2 :style="titleSize">班级信息中心云平台</h2>
        <div class="class_login_font_style class_login_switch" name="tabTit">
          <span :class="password_login === true ? 'cusp on' : 'cusp'" @click="toPasswordLogin">密码登录</span>
          <span :class="qrcode_login === true ? 'cusp on' : 'cusp'" @click="toQrCodeLogin">扫码登录</span>
        </div>
        <div v-if="password_login === true">
          <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px">
            <h3 class="title"></h3>
            <el-form-item prop="username">
              <el-input type="text" v-model="ruleForm.username" auto-complete="off" placeholder="账号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" v-model="ruleForm.password" auto-complete="off" placeholder="密码"></el-input>
            </el-form-item>
            <el-checkbox class="remember" v-model="rememberpwd">记住密码</el-checkbox>
            <el-form-item style="width:100%; margin-top: 10px">
              <el-button class="button" @click="submitForm('ruleForm')" :loading="logining">登录</el-button>
            </el-form-item>
            <el-form-item>
              <el-divider>测试环境</el-divider>
              <div class="img_box">
                <img width="45px" src="../assets/img/QQ-circle-fill.png" class="img_style" @click="toQQLogin" alt="QQ登录">
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="qrcode_login === true">
          <h3 class="qrcode_title">请使用微信扫描下方二维码完成登录</h3>
          <div class="img_box" v-loading="WxLogin_loading">
            <img width="200px" height="200px" :src="WxLogin">
          </div>
          <h3 class="qrcode_title">{{ WxLogin_msg }}</h3>
        </div>
      </div>
      <div class="illustration" v-if="isShow"></div>
    </div>
    <div class="footer" v-if="!onlyShowCopyright">
      &copy; 2022-2024 <a href="./about.html" target="_blank"
                          style="display:inline-block;color: #ffffff;">班级信息中心云平台测试版</a>&nbsp;|&nbsp;<a
        href="https://beian.miit.gov.cn/" target="_blank"
        style="display:inline-block;color:#ffffff;">闽ICP备2021019287号-1</a>&nbsp;
      |&nbsp;<a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=35058202000622"
                style="display:inline-block;color:#ffffff;">
      <img alt="公安logo" src="../assets/img/gonganbeian.png" style="float:left;"/>&nbsp;闽公网安备 35058202000622号</a>
    </div>
    <div class="footer" v-else>
      &copy; 2022-2024 <a href="./about.html" target="_blank"
                          style="display:inline-block;color: #ffffff;">班级信息中心云平台测试版</a>&nbsp;|&nbsp;<a
        href="https://beian.miit.gov.cn/" target="_blank"
        style="display:inline-block;color:#ffffff;">闽ICP备2021019287号-1</a>&nbsp;
    </div>
  </div>

</template>

<script>
import default_QrCode from "@/assets/img/qrcode.png";
import {delCookie, getCookie, setCookie} from "@/utils/util";
import md5 from "js-md5";
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      logining: false,
      rememberpwd: false,
      ruleForm: {
        username: '',
        password: '',
      },
      isShow: true,
      onlyShowCopyright: false,
      rules: {
        username: [{required: true, message: '请输入账号', trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}]
      },
      password_login: true,
      qrcode_login: false,
      WxLogin: default_QrCode,
      WxLogin_loading: true,
      WxLogin_msg: '',
      timerCreated: false,
      exteraStyle: 'padding: 25px 40px',
      boxExteraStyle: 'width: 70%',
      titleSize: 'font-size: 40px'
    }
  },
  methods: {
    getuserpwd() {
      if (getCookie('user') !== '' && getCookie('pwd') !== '') {
        this.ruleForm.username = getCookie('user')
        this.ruleForm.password = getCookie('pwd')
        this.rememberpwd = true
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.logining = true
          let orign_password = this.ruleForm.password
          let encode_password = md5(md5(this.ruleForm.password) + '_ljcsys')

          let loginData = new FormData();
          loginData.append('username', this.ruleForm.username)
          loginData.append('password', encode_password)

          this.axios({
            method: "post",
            url: '/api/login.php?action=login',
            data: loginData,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
              .then(res => {
                if (res.data.code == 200) {
                  if (this.rememberpwd) {
                    setCookie('user', this.ruleForm.username, 7)
                    setCookie('pwd', orign_password, 7)
                  } else {
                    delCookie('user')
                    delCookie('pwd')
                  }
                  this.$message.success("登录成功！欢迎你！")
                  setTimeout(() => {
                    this.logining = false
                    localStorage.setItem('isLogin', 'true');
                    localStorage.setItem('name', res.data.username);
                    localStorage.setItem('activeTab', '/user/UserCenter');

                    if (this.$route.query.refer != null && this.$route.query.refer != '/') this.$router.push({path: this.$route.query.refer});
                    else this.$router.push({path: '/user/UserCenter'});
                  }, 1000)
                } else {
                  this.$message.error(res.data.msg)
                  this.logining = false
                  return false
                }
              }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    handleResize() {
      if (window.innerWidth < 1156) {
        this.isShow = true
        this.titleSize = 'font-size: 35px'

        if (window.innerWidth < 1029) {
          this.isShow = false
          this.titleSize = 'font-size: 30px'

          if (window.innerWidth < 628) {
            this.exteraStyle = 'padding: 20px 25px'
            this.boxExteraStyle = 'width: 80%'
            this.titleSize = 'font-size: 30px'

            if (window.innerWidth < 500) {
              this.exteraStyle = 'padding: 12px 25px'
              this.boxExteraStyle = 'width: 90%'
              this.onlyShowCopyright = true
            } else {
              this.onlyShowCopyright = false
              this.exteraStyle = 'padding: 20px 25px'
              this.boxExteraStyle = 'width: 80%'
            }

          } else {
            this.titleSize = 'font-size: 30px'
            this.exteraStyle = 'padding: 35px 45px'
            this.boxExteraStyle = 'width: 65%'
          }
        } else {
          this.isShow = true

          this.titleSize = 'font-size: 35px'
          this.exteraStyle = 'padding: 35px 45px'
          this.boxExteraStyle = 'width: 70%'
        }
      } else {
        this.titleSize = 'font-size: 40px'
        this.isShow = true

      }
    },
    toQQLogin() {
      if (this.$route.query.refer != null && this.$route.query.refer != '/') window.open('https://class.ljcljc.cn/oauth/qq_login.php?redirect=' + this.$route.query.refer, '_self');
      else window.open('https://class.ljcljc.cn/oauth/qq_login.php', '_self');
    },
    toPasswordLogin() {
      this.password_login = true
      this.qrcode_login = false
    },
    toQrCodeLogin() {
      this.password_login = false
      this.qrcode_login = true

      if (this.timerCreated == false) {
        this.WxLogin_loading = true
        this.WxLogin_msg = ''
        this.timerCreated = true
        axios.get('/api/wx/getLoginCode.php')
            .then(res => {
              if (res.data.code === 200) {
                this.WxLogin = res.data.qrcode
                this.WxLogin_loading = false

                const CheckStatus = () => {
                  axios.get('/api/wx/getstatus.php', {params: {scene: res.data.scene}})
                      .then(rs => {
                        if (rs.data.code == 200) {
                          console.log(rs.data.msg)
                          this.WxLogin_loading = true
                          this.$message.success('登录成功！')
                          this.WxLogin_msg = '登录成功准备跳转'
                          this.timerCreated = false
                          clearTimeout(checklogin);
                          setTimeout(() => {
                            this.logining = false
                            localStorage.setItem('isLogin', 'true');
                            localStorage.setItem('name', rs.data.username);
                            localStorage.setItem('activeTab', '/user/UserCenter');

                            if (this.$route.query.refer != null && this.$route.query.refer != '/') this.$router.push({path: this.$route.query.refer});
                            else this.$router.push({path: '/user/UserCenter'});
                          }, 1000)
                        } else {
                          console.log(rs.data.msg)
                          if (rs.data.code == 202) {
                            this.WxLogin_msg = rs.data.msg
                          }
                        }
                        guoqi();
                      })
                      .catch(errr => {
                        this.WxLogin_msg = '认证服务器发生错误，请尝试刷新或更换登录方式'
                        this.WxLogin = default_QrCode
                        this.timerCreated = false
                      })
                }
                let TimeOut = 0;

                const guoqi = () => {
                  TimeOut += 3;
                  if (TimeOut >= 60) {
                    clearTimeout(checklogin);
                    this.$message.error('二维码已过期，请刷新页面')
                    this.WxLogin_msg = '二维码已过期，请刷新页面'
                    this.WxLogin = default_QrCode
                    this.timerCreated = false
                  }
                }
                let checklogin = setInterval(CheckStatus, 3000);

              }
            })
            .catch(err => {
              this.$message.error(err)
              this.timerCreated = false
            })
      }


    },
    handleEnterKey(event) {
      if (event.keyCode === 13) {
        this.submitForm('ruleForm');
      }
    }
  },
  created() {
    this.getuserpwd()
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', this.handleEnterKey);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('keydown', this.handleEnterKey);
  }
}
</script>


<style scoped>

.login {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #6553ff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../assets/newLogin/login_bg.jpg");
  background-size: cover;
}

.container {
  width: 70%;
  max-width: 1200px;
  background: linear-gradient(135deg, #0d40c7, #7461fd);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  padding: 0;
}

.login-form {
  flex: 1;
  background: white;
  border-radius: 20px;
  width: 100px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  margin-bottom: 40px;
  color: #0356CA;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-shadow: 1px 1px 3px rgba(101, 83, 255, 0.4);

}

.login-form .button {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background: linear-gradient(135deg, #6553ff, #00aaff);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);

  border: none;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.login-form .button:hover {
  background: linear-gradient(135deg, #4a3cb7, #0077cc);
}

.login-form .button:active {
  transform: scale(0.98);
}

.illustration {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url('../assets/newLogin/page_image.webp') no-repeat center center;
  background-size: contain;
}


.class_login_switch .on {
  font-size: 20px;
  color: #000;
  position: relative;
}

.class_login_switch .on::after {
  content: "";
  display: block;
  background: #0356CA;
  border-radius: 888px;
  width: 30px;
  height: 4px;
  position: absolute;
  left: 23px;
  bottom: -10px;
}

.cusp {
  cursor: pointer;
  margin-right: 40px;
}

.img_box {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.img_style {
  vertical-align: middle;
}

.class_login_font_style {
  font-size: 20px;
  color: #8b8b8b;
}

.qrcode_title {
  margin: 30px auto 40px auto;
  text-align: center;
  color: #505458;
  font-size: 16px;
}

.footer {
  height: 50px;
  z-index: 0;
  color: #ffffff;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}
</style>
