<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/mytask' }">任务中心</el-breadcrumb-item>
        <el-breadcrumb-item>{{ collect_data.title }}-提交区</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="collect_data.ifrank === false ? 24 : 16"
          :lg="collect_data.ifrank === false ? 24 : 16" :xl="collect_data.ifrank === false ? 24 : 16">
          <el-card v-loading="loading">
            <div slot="header">
              <span>{{ collect_data.title }}-提交区</span>
              <el-tag style="float: right;" v-if="collect_data.remain_time > 0">剩余{{ collect_data.remain_time }}小时</el-tag>
              <el-tag style="float: right;" v-if="collect_data.remain_time == 0" type="danger">已结束</el-tag>
            </div>
            <div style="font-size:20px;text-align:center;line-height:40px; padding: 0;">

              <h2>请上传 {{ collect_data.title }}</h2>
              允许重复提交，重复提交版本将覆盖原始版本！<br>
              <p v-html="collect_data.sj_notice"></p><br>
              <span>姓名：{{ username }}<br></span>
              <span>完成状态：<el-tag v-if="collect_data.if_finish === true" type="success" size="medium" plain>已完成</el-tag>
                <el-tag v-if="collect_data.if_finish === false" type="danger" size="medium"
                  plain>未完成</el-tag></span><br>
                  
              <span v-if="collect_data.ifshenhe === true && collect_data.if_finish === true">审核状态：<el-tag v-if="collect_data.shenhe == 0" type="warning" size="medium" plain>待审核</el-tag>
                <el-tag v-if="collect_data.shenhe == 1" type="success" size="medium" plain>审核通过</el-tag>
                <el-tag v-if="collect_data.shenhe == 2" type="danger" size="medium" plain>审核驳回</el-tag><br>
              </span><br>

              <div v-if="collect_data.status === 2 || collect_data.status === 4">
                <el-button size="small" type="primary" @click="dialogVisible = true">提交文件</el-button>
              </div>
              <div
                v-if="collect_data.if_finish === true || (collect_data.if_finish === false && (collect_data.status === 2 || collect_data.status === 4))">
                <el-button size="small" type="success" @click="handleDownload"
                  :disabled="collect_data.if_finish == false">下载提交的文件</el-button>
              </div>
              <el-button size="small" type="danger"
                v-if="collect_data.if_finish === false && collect_data.status === 1">未开始，不允许提交</el-button>
              <el-button size="small" type="danger"
                v-if="collect_data.if_finish === false && collect_data.status === 3">已超过提交时间，不允许提交</el-button>
            </div>
            <el-divider content-position="left">发布者：{{ collect_data.poster }}</el-divider>
            <el-divider content-position="left">截止时间：{{ collect_data.jiezhi_time }}</el-divider>
          </el-card>
        </el-col>

        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" v-if="collect_data.ifrank === true">
          <el-card v-loading="rank_loading">
            <div slot="header">
              <span>完成排行榜</span>
              <el-tooltip class="item" effect="dark" content="排名仅展示前十哦" placement="top">
                <i class="el-icon-info" style="font-size: 15px;"></i>
              </el-tooltip>
            </div>
            <div style="height:402px;">
              <el-scrollbar style="height:100%">
                <el-table style="width: 100%" :data="rankData">
                  <el-table-column prop="rank" label="排名" header-align="center" align="center">
                    <template slot-scope="scope">
                      <img v-if="scope.row.rank == 1" width="22px" height="22px" src="../assets/img/goldmedal.png">
                      <img v-else-if="scope.row.rank == 2" width="22px" height="22px" src="../assets/img/silvermedal.png">
                      <img v-else-if="scope.row.rank == 3" width="22px" height="22px" src="../assets/img/bronzemedal.png">
                      <span v-else>{{ scope.row.rank }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="name" label="姓名" header-align="center" align="center"></el-table-column>
                </el-table>
              </el-scrollbar>
            </div>
          </el-card>
        </el-col>

      </el-row>

      <el-dialog title="上传文件" :visible.sync="dialogVisible" :width="create_width" :before-close="handleClose">
        <el-upload class="upload-demo" drag ref="upload" action="" :before-upload="beforeUpload" :on-change="handleChange"
          :on-remove="handleRemove" :on-exceed="handleExceed" :multiple="false" :accept="collect_data.ext"
          :file-list="fileList" :auto-upload="false" :limit="1">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip"><span style="font-size:13px;color:red;">允许提交的文件后缀：{{
            collect_data.ext
          }}</span></div>
        </el-upload>
        <div v-show="progressFlag">
          <el-progress :text-inside="true" :stroke-width="14" :percentage="progressPercent"
            status="success"></el-progress>
        </div>
        <div style="text-align: center;">
          <el-button v-show="post_btn" style="margin: 10px 0;" icon="el-icon-upload2" size="medium" type="primary"
            :loading="uploading" @click="my_upload">
            确认上传
          </el-button><br>
          <el-button style="margin-left: 0" icon="el-icon-circle-close" size="medium" @click="handleClose">
            关闭窗口
          </el-button>
        </div>
      </el-dialog>

    </el-main>
</el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}

.upload-demo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
<style>
.el-upload input {
  display: none !important;
}

.el-table .el-table__cell {
  padding: 6px 0 !important;
}
</style>

<script>
import axios from 'axios'
export default {
  name: 'Collect_file',
  data() {
    return {
      loading: true,
      rank_loading: true,
      post_btn: true,
      uploading: false,
      progressFlag: false,
      progressPercent: 10,
      dialogVisible: false,
      fileList: [],
      collect_data: {},
      rankData: [],
      username: localStorage.getItem('name'),
      create_width: '40%'

    };
  },
  methods: {
    beforeUpload(file) {
      let size = file.size / 1024 / 1024;
      if (size > 50) {
        this.$message.warning("文件大小不得超过50M");
      }
    },
    handleChange(file, fileList) {
      if (file) {
        this.fileList = fileList.slice(-3);
      }
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        '只能选择1个文件，如果要重新选择文件，请先在上传列表中删除原来的文件'
      );
    },
    my_upload() {
      if (this.fileList.length === 0) {
        this.$message.warning("请点击上传文件，添加文件");
      }
      else {
        let form = new FormData();
        let batch_file = this.fileList[0].raw;
        form.append('file', batch_file);
        let that = this
        that.progressFlag = true
        this.axios({
          method: 'post',
          headers: {
            "Content-Type": "multipart/form-data"
          },
          url: '/api/CollectController.php',
          params: { method: 'upload', id: this.$route.params.id },
          timeout: 180000,
          data: form,
          onUploadProgress: progressEvent => {
            that.progressPercent = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(0) | 0
          }
        }).then((res) => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

          if (res.data.code == 200 && that.progressPercent === 100) {
            this.post_btn = false
            this.collect_data.if_finish = true

            if (res.data.shenhe === false) {
              this.collect_data.ifshenhe = false;
            } else {
              this.collect_data.shenhe = res.data.shenhe
              this.collect_data.ifshenhe = true
            }

            if (res.data.rank == '' || res.data.rank == null || res.data.rank.length === 0 || res.data.rank == false) {
              this.collect_data.ifrank = false;
              this.rankData = null;
            } else {
              this.collect_data.ifrank = true;
              this.rankData = res.data.rank;
            }

            this.$message.success('上传完成');

          } else {
            this.$message.error('上传失败！' + res.data.msg);
          }
        }).catch((err) => {
          this.$message.error(err);
        })
      }

    },
    handleClose(done) {
      if (this.post_btn === true) {
        this.$confirm('确认关闭？')
          .then(_ => {
            this.dialogVisible = false;
            this.$refs['upload'].clearFiles();
            this.progressFlag = false;
            this.fileList = [];
            done();
          })
          .catch(_ => { });
      }
      else {
        this.dialogVisible = false;
        this.$refs['upload'].clearFiles();
        this.progressFlag = false;
        this.fileList = [];
        this.progressPercent = 10;
        this.post_btn = true;
      }

    },
    handleDownload() {
      this.$message.success('成功发起下载请求，请耐心等待文件下载完成');
      let a = document.createElement('a')
      a.href = '/api/CollectController.php?method=download&id=' + this.$route.params.id
      a.click();
    },
    getCollectData() {
      axios.get('/api/CollectController.php', { params: { method: 'get', id: this.$route.params.id } })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);

          let Base64 = {
            decode(str) {
              return decodeURIComponent(atob(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }).join(''));
            }
          };

          const currentTime = new Date();
          const jiezhiTime = new Date(res.data.jiezhi_time);
          const remainTime = jiezhiTime > currentTime ? Math.floor((jiezhiTime - currentTime) / (1000 * 60 * 60)) : 0;


          this.collect_data = res.data
          this.collect_data.remain_time = remainTime
          this.collect_data.sj_notice = Base64.decode(this.collect_data.sj_notice)
          if (res.data.rank == '' || res.data.rank == null || res.data.rank.length === 0) {
            this.collect_data.ifrank = false;
            this.rankData = null;
          } else {
            this.collect_data.ifrank = true;
            this.rankData = res.data.rank;
          }

          this.loading = false
          this.rank_loading = false
        }).catch(err => {
          console.log(err)
        })
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.create_width = "100%"
      } else {
        this.create_width = "40%"
      }
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true
    this.rank_loading = true
    this.getCollectData()
  }
};
</script>
