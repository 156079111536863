<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/CareerReport' }">职业生涯规划</el-breadcrumb-item>
        <el-breadcrumb-item>职业生涯规划报告</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-main>
      <el-row :gutter="15">
        <el-col :span="14">
          <el-card>
            <template #header>
              <div class="card-header">
                <span><strong>测评结果概述</strong></span>
              </div>
            </template>
            <div style="height: 100px; text-indent:2em; overflow-y: auto;">
              {{ describe }}
            </div>
          </el-card>
          <el-card style="margin-top: 15px">
            <template #header>
              <div class="card-header">
                <span><strong>职业规划建议</strong></span>
              </div>
            </template>
            <div style="height: 100px; text-indent:2em; overflow-y: auto;">
              {{ suggestions }}
            </div>
          </el-card>
        </el-col>
        <!-- 职业方向南丁格尔玫瑰图 -->
        <el-col :span="10">
          <el-card>
            <div slot="header">
              <span><strong>职业方向</strong></span>
            </div>
            <div ref="jobNightingaleChart" id="jobNightingaleChart" style="height: 350px;"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <!-- 技能雷达图 -->
        <el-col :span="12">
          <el-card>
            <div slot="header">
              <span><strong>技能匹配度</strong></span>
            </div>
            <div ref="skillRadarChart" id="skillRadarChart" style="height: 400px;"></div>
          </el-card>
        </el-col>
        <!-- 能力雷达图 -->
        <el-col :span="12">
          <el-card>
            <div slot="header">
              <span><strong>能力匹配度</strong></span>
            </div>
            <div ref="abilityRadarChart" id="abilityRadarChart" style="height: 400px;"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <!-- 职业方向表格 -->
        <el-col :span="24">
          <el-table
              :data="jobData"
              style="width: 100%"
              row-key="id"
              :expand-row-keys="expandRowKeys"
              @expand-change="handleExpandChange"
          >
            <el-table-column type="expand">
              <template #default="props">
                <el-form label-position="left" inline class="demo-table-expand">
                  <el-form-item label="推荐理由：" style="margin-left: 60px">
                    <span>{{ props.row.reason }}</span>
                  </el-form-item>
                  <el-form-item label="具体建议：" style="margin-left: 60px">
                    <span>{{ props.row.suggest }}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="职业方向"></el-table-column>
            <el-table-column prop="value" header-align="center" align="center" label="匹配度"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-main>
  </el-main>
</template>

<script>
import * as echarts from 'echarts';
import axios from 'axios';
import {ref} from "vue";

export default {
  data() {
    return {
      describe: '',
      jobNightingaleChart: "jobNightingaleChart",
      skillRadarChart: "skillRadarChart",
      abilityRadarChart: "abilityRadarChart",
      suggestions: '',
      jobData: [],
      skillData: [],
      abilityData: [],
      expandRowKeys: [],
      chartInstances: {},
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get('/api/getCareerReport.php?method=getDetail&reportId=' + this.$route.params.id);
        const OriginData = response.data.data;
        console.log(OriginData)
        const ResultData = JSON.parse(OriginData)
        const data = ResultData.result
        this.describe = data.describe;
        this.suggestions = data.suggestions;
        this.skillData = data.skillChart;
        this.abilityData = data.abilityChart;
        this.jobData = data.jobChart.map((item, index) => ({ id: index, ...item }));
      } catch (error) {
        console.error('Failed to fetch data:', error);
        this.$message.error('数据加载失败！');
      }
    },
    onBack() {
      this.router.push('/user/CareerReport');
    },
    initCharts() {
      this.initNightingaleChart(this.$refs.jobNightingaleChart, '职业方向', this.jobData);
      this.initRadarChart(this.$refs.skillRadarChart, '技能', this.skillData);
      this.initRadarChart(this.$refs.abilityRadarChart, '能力', this.abilityData);
    },
    initNightingaleChart(dom, title, data) {
      const myChart = this.$echarts.init(dom);

      myChart.setOption({
        title: { text: title, left: 'center' },
        tooltip: { trigger: 'item' },
        legend: { top: 'bottom' },
        series: [{
          name: title,
          type: 'pie',
          radius: [20, 100],
          center: ['50%', '50%'],
          color: [
            '#50C878',
            '#fb7293',
            '#409EFF',
            '#FF7043',
            '#C678BF'
          ],
          roseType: 'radius',
          itemStyle: { borderRadius: 5 },
          label: { show: false },
          emphasis: { label: { show: true }},
          data: data.map(item => ({ value: item.value, name: item.title }))
        }]
      });
      this.chartInstances[title] = myChart;
    },
    initRadarChart(dom, title, data) {
      const myChart = echarts.init(dom);
      myChart.setOption({
        title: { text: title, left: 'center' },
        tooltip: {},
        legend: { data: [title], top: 'bottom' },
        radar: { indicator: data.map(item => ({ name: item.title, max: 10 })) },
        series: [{ name: title, type: 'radar', data: [{ value: data.map(item => item.value), name: title }] }]
      });
      this.chartInstances[title] = myChart;
    },
    resizeCharts() {
      Object.values(this.chartInstances).forEach(chart => {
        chart.resize();
      });
    }
  },
  mounted() {
    this.fetchData().then(() => {
      this.$nextTick(() => {
        this.initCharts();
        window.addEventListener('resize', this.resizeCharts);
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeCharts);
  }
};
</script>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>
