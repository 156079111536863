<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item>账号管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>

      <el-input v-model="inputContent" placeholder="请输入姓名或学号进行查询" prefix-icon="el-icon-search"
        style="width: 500px;"></el-input>&nbsp;<el-button icon="el-icon-search" type="primary" plain
        @click="searchput">搜索</el-button><br><br>

      <el-table v-loading="loading" :data="tables[0].slice((currentPage - 1) * pagesize, currentPage * pagesize)"
        style="width: 100%">
        <el-table-column prop="id" label="序号" min-width="60px" header-align="center" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" min-width="100px" header-align="center"
          align="center"></el-table-column>
        <el-table-column prop="classid" label="账号" min-width="100px" header-align="center"
          align="center"></el-table-column>
        <el-table-column label="初始密码" min-width="100px" header-align="center" align="center">
          <template slot-scope="">
            <span>123456</span>
          </template>
        </el-table-column>
        <el-table-column prop="usergroup" label="用户组" min-width="100px" header-align="center" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.usergroup == 1" style="color:blue;">超级管理员</span>
            <span v-if="scope.row.usergroup == 2" style="color:red;">管理员</span>
            <span v-if="scope.row.usergroup == 3" style="color:black;">普通用户</span>
          </template>
        </el-table-column>
        <el-table-column prop="usergroup" label="切换用户组" min-width="150px" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.usergroup == 1" type="primary" size="mini" plain>超级管理员</el-button>
            <el-button v-if="scope.row.usergroup == 2" type="info" size="mini" plain
              @click="setUserGroup(scope.row, 3)">切换为普通用户</el-button>
            <el-button v-if="scope.row.usergroup == 3" type="danger" size="mini" plain
              @click="setUserGroup(scope.row, 2)">切换为管理员</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="control" label="操作" header-align="center" align="center" width="200px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="resetPasswd(scope.row)">重置密码</el-button>
            <el-button type="warning" size="mini" @click="handleEdit(scope.row.classid)">修改资料</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
        :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper" :total="count" background
        style="float: right; margin-top: 20px">
      </el-pagination>

      <el-dialog title="编辑" :visible.sync="show_edit_dialog" :width="create_width" :before-close="handleClose_edit">
        <el-form v-loading="edit_loading" element-loading-text="拼命加载中" ref="edit_form" status-icon
          :model="edit_digital_array" :rules="rules_edit" label-width="150px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="edit_digital_array.name" readonly></el-input>
          </el-form-item>
          <el-form-item label="学号" prop="classid">
            <el-input v-model="edit_digital_array.classid" readonly></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-select v-model="edit_digital_array.gender" placeholder="请选择性别">
              <el-option label="男" value="男"></el-option>
              <el-option label="女" value="女"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电话" prop="tel">
            <el-input v-model="edit_digital_array.tel"></el-input>
          </el-form-item>
          <el-form-item label="职务" prop="zhiwu">
            <el-select v-model="edit_digital_array.zhiwu" placeholder="请选择职务">
              <el-option label="普通成员" value="普通成员"></el-option>
              <el-option label="班长" value="班长"></el-option>
              <el-option label="团支书" value="团支书"></el-option>
              <el-option label="副班长" value="副班长"></el-option>
              <el-option label="学习委员" value="学习委员"></el-option>
              <el-option label="组织委员" value="组织委员"></el-option>
              <el-option label="文体委员" value="文体委员"></el-option>
              <el-option label="生劳委员" value="生劳委员"></el-option>
              <el-option label="纪律委员" value="纪律委员"></el-option>
              <el-option label="年级团总支副书记" value="年级团总支副书记"></el-option>
              <el-option label="年级纪律委员" value="年级纪律委员"></el-option>
              <el-option label="年级组织委员" value="年级组织委员"></el-option>
              <el-option label="年级实践委员" value="年级实践委员"></el-option>
              <el-option label="年级权益委员" value="年级权益委员"></el-option>
              <el-option label="年级宣传委员" value="年级宣传委员"></el-option>

            </el-select>
          </el-form-item>
          <el-form-item label="宿舍" prop="sushe">
            <el-input v-model="edit_digital_array.sushe"></el-input>
          </el-form-item>
          <el-form-item label="政治面貌" prop="zzmm">
            <el-select v-model="edit_digital_array.zzmm" placeholder="请选择政治面貌">
              <el-option label="群众" value="群众"></el-option>
              <el-option label="共青团员" value="共青团员"></el-option>
              <el-option label="中共预备党员" value="中共预备党员"></el-option>
              <el-option label="中共党员" value="中共党员"></el-option>
              <el-option label="民主党派成员" value="民主党派成员"></el-option>
              <el-option label="无党派人士" value="无党派人士"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitToEdit('edit_form')">确认修改</el-button>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose_edit">取 消</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>

<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}
</style>

<script>
import axios from 'axios';
export default {
  name: 'List',
  data() {
    return {
      loading: true,
      show_digital_array: {},
      show_digital_dialog: false,
      tableData: [],
      edit_digital_array: {},
      show_edit_dialog: false,

      edit_loading: false,

      create_width: '60%',

      searchContent: '',
      inputContent: '',

      rules_edit: {
        gender: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        tel: [
          { required: true, message: '请输入电话号码', trigger: 'blur' }
        ],
        zhiwu: [
          { required: true, message: '请选择职务', trigger: 'change' }
        ],
        sushe: [
          { required: true, message: '请输入宿舍', trigger: 'blur' }
        ],
        zzmm: [
          { required: true, message: '请选择政治面貌', trigger: 'change' }
        ],
      },

      currentPage: 1,  //默认初始页
      pagesize: 10,    //每页默认显示的数据
      pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改

    };
  },
  methods: {
    handleSizeChange: function (size) {
      this.pagesize = size;
    },
    //切换页码
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
    },
    handleClose_edit(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.edit_digital_array = {};
          this.show_edit_dialog = false;
          done();
        })
        .catch(_ => { });
    },
    handleShow(row) {
      this.show_digital_dialog = true
      this.show_digital_array = row
    },
    handleEdit(id) {
      this.show_edit_dialog = true
      this.edit_loading = true

      axios.get('/api/AccountController.php', { params: { method: 'getDetail', id: id } })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

          if (res.data.code != 200) {
            this.$message.error(res.data.msg)
          }
          else {

            this.edit_digital_array = res.data.datas

            this.edit_loading = false
          }
        }).catch(err => {
          this.$message.error(err)
        })
    },

    searchput() {
      this.searchContent = this.inputContent
    },

    getList() {
      axios.get('/api/AccountController.php', { params: { method: 'manage' } })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);

          this.tableData = res.data
          this.pageCount = res.data.length
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
    },
    setUserGroup(formdata, leixing) {
      let name = formdata.name;
      let new_qx;
      if (leixing == 2) new_qx = "管理员";
      if (leixing == 3) new_qx = "普通成员";

      this.$confirm('确定要将' + name + '设置为' + new_qx + '吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.get('/api/AccountController.php', { params: { method: 'setUserGroup', uid: formdata.classid, group: leixing } })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            }
            else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: '设置成功!'
              });
              this.$set(this.tableData[formdata.id - 1], 'usergroup', leixing);
            } else {
              this.$message.error('失败：' + res.data.msg)
            }
          }).catch(err => {
            console.log(err)
          })
      })
        .catch(err => {
          console.log(err)
        })
    },
    resetPasswd(formdata) {
      let name = formdata.name;

      this.$confirm('确定要重置' + name + '的密码为默认密码（123456）吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.get('/api/AccountController.php', { params: { method: 'resetPasswd', uid: formdata.classid } })
          .then(res => {
            if (res.data.code != null && res.data.code == 400) {
              this.$message.error('登录状态过期，请重新登录');
              localStorage.clear('name');
              localStorage.clear('isLogin');
              this.$router.push('/login?refer=' + window.location.pathname)
            }
            else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

            if (res.data.code == 200) {
              this.$message({
                type: 'success',
                message: '重置成功!'
              });
            } else {
              this.$message.error('失败：' + res.data.msg)
            }
          }).catch(err => {
            console.log(err)
          })
      })
        .catch(err => {
          console.log(err)
        })
    },
    onSubmitToEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          let data = new FormData();

          data.append('classid', this.edit_digital_array.classid);
          data.append('gender', this.edit_digital_array.gender);
          data.append('tel', this.edit_digital_array.tel);
          data.append('zhiwu', this.edit_digital_array.zhiwu);
          data.append('sushe', this.edit_digital_array.sushe);
          data.append('zzmm', this.edit_digital_array.zzmm);

          this.axios({
            method: "post",
            url: '/api/AccountController.php?method=edit',
            data: data,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
            .then(res => {
              if (res.data.code != null && res.data.code == 400) {
                this.$message.error('登录状态过期，请重新登录');
                localStorage.clear('name');
                localStorage.clear('isLogin');
                this.$router.push('/login?refer=' + window.location.pathname)
              }
              else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

              if (res.data.code == 200) {
                for (var i = 0; i < this.tableData.length; i++) {
                  if (this.tableData[i].id == this.edit_digital_array.id) {
                    this.tableData[i] = JSON.parse(JSON.stringify(this.edit_digital_array))
                    break;
                  }
                }
                this.$message.success('修改成功！');
                this.$refs[formName].resetFields();
                this.show_edit_dialog = false;
              }
            }).catch(err => {
              console.log(err)
            })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.create_width = "100%"
      } else {
        this.create_width = "50%"
      }
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true;
    this.getList();
  },
  computed: {
    tables() {
      const search = this.searchContent;

      if (this.inputContent == '') {
        this.searchContent = ''
        this.currentPage = 1
        return [this.tableData, this.count = this.tableData.length];
      }
      if (search !== '') {
        return [this.tableData.filter((dataNews) => {
          return Object.keys(dataNews).some((key) => {
            return String(dataNews[key]).toLowerCase().indexOf(search) > -1;
          });
        }),
        this.count = this.tableData.filter((dataNews) => {
          return Object.keys(dataNews).some((key) => {
            return String(dataNews[key]).toLowerCase().indexOf(search) > -1;
          });
        }).length];
      }
      return [this.tableData, this.count = this.tableData.length];
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
