<template>
    <el-main style="padding:0px">
        <div class="breadcrumb_box">
            <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
            <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
                <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
                <el-breadcrumb-item>投票</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-main>
            <div class="tip">
                匿名模式下，系统不会关联投票者与投票者的投票内容，所以投票完成后无法查看本人的投票结果。
            </div>
            <el-table v-loading="loading" :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
                style="width: 100%">
                <el-table-column prop="id" label="序号" width="100px" header-align="center" align="center"></el-table-column>
                <el-table-column prop="title" :show-overflow-tooltip="true" label="投票标题" width="300px"></el-table-column>
                <el-table-column prop="if_finish" label="是否完成" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.if_finish === true" type="success" size="medium" plain>已完成</el-tag>
                        <el-tag v-if="scope.row.if_finish === false" type="danger" size="medium" plain>未完成</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="isanonymous" label="投票类别" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.isanonymous === true" type="primary" size="medium" plain>匿名</el-tag>
                        <el-tag v-if="scope.row.isanonymous === false" type="warning" size="medium" plain>实名</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态" width="140px" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 1" type="primary" size="medium" plain>未开始</el-tag>
                        <el-tag v-if="scope.row.status == 2" type="success" size="medium" plain>进行中</el-tag>
                        <el-tag v-if="scope.row.status == 3" type="danger" size="medium" plain>已结束</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="poster" label="发布人" header-align="center" align="center"></el-table-column>
                <el-table-column prop="post_time" label="发布时间" width="160px"></el-table-column>
                <el-table-column label="操作" width="100px" header-align="center" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="info" v-if="scope.row.status === 1">不可操作</el-button>
                        <el-button size="mini" type="danger" v-else-if="scope.row.if_finish === false && scope.row.status === 2"
                            @click="toVote(scope.row.unid)">前往投票</el-button>
                        <el-button size="mini" type="info" v-else-if="scope.row.if_finish === false && scope.row.status === 3">不可操作</el-button>
                        <el-button size="mini" type="success" v-else-if="scope.row.isanonymous === false"
                            @click="toVote(scope.row.unid)">查看详情</el-button>
                        <el-button size="mini" type="info"
                            v-else-if="scope.row.isanonymous === true">不可操作</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="7"
                :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper" :total="tableData.length > 0 ? tableData.length : null"
                background style="float: right; margin-top: 20px">
            </el-pagination>

        </el-main>
    </el-main>
</template>
  
<style scoped>
.breadcrumb_box {
    height: 40px;
    width: 100%;
    background-color: #f6f8f8;
    border-bottom: 1px solid #eee;
    padding: 15px;
    margin: 0;
    display: flex;
}

.breadcrumb_content {
    font-size: 15px;
    position: absolute;
    margin: auto;
    left: 40px;
}

.shenhe_class {
    background-color: #fdf6ec;
    border: 1px solid #e6a23c;
    color: #e6a23c;
    font-size: 12px;
    border-radius: 3px;
    margin-right: 3px;
}

.tip {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 0 0 5px 0;
}

.need_class {
    background-color: #ecf3fd;
    border: 1px solid #3c80e6;
    color: #3c80e6;
    font-size: 12px;
    border-radius: 3px;
    margin-right: 3px;
}
</style>
  
<script>
import axios from 'axios'
export default {
    name: 'Mytask',
    data() {
        return {
            activeName: 'first',
            tableData: [],

            loading: true,

            currentPage: 1,  //默认初始页
            pagesize: 10,    //每页默认显示的数据
            pageCount: 0,   //数据的总条数,如果数据是后端接口返回的，则此值需修改


        };
    },
    methods: {
        handleSizeChange: function (size) {
            this.pagesize = size;
        },
        //切换页码
        handleCurrentChange: function (currentPage) {
            this.currentPage = currentPage;
        },
        toVote(id) {
            this.$router.push('/user/vote/' + id)
        },
        getVoteList() {
            axios.get('/api/getVoteList.php')
                .then(res => {
                    if (res.data.code != null && res.data.code == 400) {
                        this.$message.error('登录状态过期，请重新登录');
                        localStorage.clear('name');
                        localStorage.clear('isLogin');
                        this.$router.push('/login?refer=' + window.location.pathname)
                    }
                    else if (res.data.msg != null) this.$message.error(res.data.msg);

                    this.tableData = res.data.votelist
                    this.pageCount = res.data.votelist.length
                    this.loading = false
                }).catch(err => {
                    console.log(err)
                })
        }
    },
    created() {
        this.loading = true;
        this.getVoteList();
    }
};
</script>
  