<template>
  <el-main style="padding:0px">
    <div class="breadcrumb_box">
      <i class="el-icon-discover" style="color: #333333;font-size: 15px;padding:0;margin:0;"></i>
      <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb_content">
        <el-breadcrumb-item :to="{ path: '/user/UserCenter' }">班级信息中心</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user/vote' }">投票</el-breadcrumb-item>
        <el-breadcrumb-item>{{ vote_data.title }}-投票区</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-main>
      <el-card v-loading="loading">
        <div slot="header">
          <span style="font-size:19px;">{{ vote_data.title }}-投票区</span>
        </div>
        <div style="font-size:20px;text-align:center;line-height:40px;">
          <h2>{{ vote_data.title }}</h2>
          <h3>请珍惜本次投票机会，秉持<b>客观</b>、<b>公正</b>、<b>公平</b>的原则投票</h3>
          <h3 v-show="vote_data.isanonymous === true">本次投票为匿名投票，系统不会记录保存共享投票者的相关信息</h3><br>
          <div style="max-width:400px;margin-left: auto;margin-right: auto;">
            <el-form v-loading="loading" element-loading-text="拼命加载中" ref="voteform" status-icon :model="formData"
              :rules="rules_vote">
              <el-form-item label="参选人员" prop="select_peoples">
                <span style="font-size: 20px;">请选择<strong>{{ vote_data.select_num }}位</strong>你要投票的对象</span><br>
                <label v-for="item in people_list" :key="item.id">
                  <input type="checkbox" v-model="formData.select_peoples" :value="item.id"
                    :disabled="vote_data.if_finish">
                  <span
                    :class="{ 'is-checked': formData.select_peoples.indexOf(item.id) > -1, 'disabled': vote_data.if_finish }"><span>{{
                      item.name }}</span></span>
                </label>
              </el-form-item>
            </el-form>
          </div>
          <br>
          投票状态： <el-tag v-if="vote_data.if_finish === true" type="success" size="medium" plain>已投票</el-tag>
          <el-tag v-if="vote_data.if_finish === false" type="danger" size="medium" plain>未投票</el-tag><br><br>

          <span v-if="vote_data.if_finish === false" style="font-size: 16px;">提示：投票提交成功后，无法修改结果</span><br>
          <el-button type="success" v-if="vote_data.if_finish === false && vote_data.status != 3 && vote_data.status != 1"
            @click="vote">提交投票</el-button>
          <el-button type="danger"
            v-if="vote_data.if_finish === false && (vote_data.status == 3 || vote_data.status == 1)">超时不可完成</el-button>

        </div>
        <el-divider content-position="left">发布者：{{ vote_data.poster }}</el-divider>

      </el-card>
      <el-dialog title="提示" :visible.sync="show_confirm_dialog" :width="confirm_dialog_width">
        <span style="font-size:20px;"><strong>我承诺：</strong>我的投票是秉持客观、公正、公平的态度投出的，并已知晓提交后无法重新投票</span><br>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="show_confirm_dialog = false">取消</el-button>
          <el-button type="primary" size="mini" @click="submitVote" :loading="voting">确定</el-button>
        </span>
      </el-dialog>
    </el-main>
  </el-main>
</template>
  
<style scoped>
.breadcrumb_box {
  height: 40px;
  width: 100%;
  background-color: #f6f8f8;
  border-bottom: 1px solid #eee;
  padding: 15px;
  margin: 0;
  display: flex;
}

.breadcrumb_content {
  font-size: 15px;
  position: absolute;
  margin: auto;
  left: 40px;
}


input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"]+span {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid rgb(215, 215, 215);
  border-radius: 5px;
  font-weight: 400;
  background-color: white;
}

input[type="checkbox"]:checked+span {
  border-color: #67C23A;
  background-color: #67C23A;
  color: white;
}
</style>
  
<script>
import axios from 'axios'
export default {
  name: 'Finish_vote',
  data() {
    return {
      show_confirm_dialog: false,
      confirm_dialog_width: "50%",
      voting: false,
      vote_data: {},
      people_list: [],
      formData: {
        select_peoples: []
      },
      rules_vote: {
        select_peoples: [
          { required: true, message: '请选择投票对象', trigger: 'blur' }
        ]
      },
      loading: true,
      form_loading: true,
      username: localStorage.getItem('name')
    };
  },
  methods: {
    vote() {
      this.$refs['voteform'].validate((valid) => {
        if (valid) {
          if (this.formData.select_peoples.length != this.vote_data.select_num) {
            this.$message({
              type: 'error',
              dangerouslyUseHTMLString: true,
              message: '投票对象数量不正确，请检查选择的人数是否为<b>' + this.vote_data.select_num + '位</b>！'
            });
            return false;
          }
          this.show_confirm_dialog = true;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitVote() {
      this.voting = true;
      this.formData.select_peoples.sort((a, b) => parseInt(a) - parseInt(b));
      let peopleArraytoString = this.formData.select_peoples.join(",");


      let data = new FormData();

      data.append('id', this.vote_data.id);
      data.append('select_people', peopleArraytoString);


      this.axios({
        method: "post",
        url: '/api/VoteController.php?method=vote',
        data: data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null && res.data.code != 200) this.$message.error(res.data.msg);

          if (res.data.code == 200) {
            this.voting = false;

            this.show_confirm_dialog = false;
            this.$message.success('投票成功！');
            this.vote_data.if_finish = true;
            if (this.vote_data.isanonymous === true) {
              this.$refs['voteform'].resetFields()
              this.$router.push('/user/vote')
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    getVoteData() {
      axios.get('/api/VoteController.php', { params: { method: 'get', id: this.$route.params.id } })
        .then(res => {
          if (res.data.code != null && res.data.code == 400) {
            this.$message.error('登录状态过期，请重新登录');
            localStorage.clear('name');
            localStorage.clear('isLogin');
            this.$router.push('/login?refer=' + window.location.pathname)
          }
          else if (res.data.msg != null) this.$message.error(res.data.msg);
          this.vote_data = res.data.votedata
          this.people_list = res.data.people_list

          if (this.vote_data.if_finish === true) {
            this.formData.select_peoples = this.vote_data.select_peoples
          }

          this.loading = false
        }).catch(err => {
          console.log(err)
        })
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.confirm_dialog_width = "100%"
      } else {
        this.confirm_dialog_width = "50%"
      }
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize);
  },
  created() {
    this.loading = true
    this.getVoteData()
  }
};
</script>
  